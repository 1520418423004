import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getHeaderFooterService, getGeneralService, getPageStaticService } from 'services/systems';
import {
  FooterDataTypes, GeneralDataTypes, HeaderDataTypes, HeaderFooterDataTypes, PageStaticTypes
} from 'services/systems/types';

interface SystemState {
  footer?: FooterDataTypes;
  header?: HeaderDataTypes;
  system?: GeneralDataTypes;
  staticPage?: PageStaticTypes[];
  pageTranslation?: Translation;
}

const initialState: SystemState = {
};

export const getHeaderFootersAction = createAsyncThunk<HeaderFooterDataTypes>(
  'systemReducer/getHeaderFooterAction',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHeaderFooterService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSystemAsync = createAsyncThunk<GeneralDataTypes>(
  'systemReducer/getSystem',
  async (_, { rejectWithValue }) => {
    try {
      return await getGeneralService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getStaticPageAsync = createAsyncThunk<PageStaticTypes[]>(
  'systemReducer/getStaticPageAsync',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getPageStaticService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    setPageTranslation($state, action: PayloadAction<Translation | undefined>) {
      $state.pageTranslation = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getHeaderFootersAction.fulfilled, ($state, action) => {
      $state.header = action.payload.header;
      $state.footer = action.payload.footer;
    });
    builder.addCase(getSystemAsync.fulfilled, ($state, action) => {
      $state.system = action.payload;
    });
    builder.addCase(getStaticPageAsync.fulfilled, ($state, action) => {
      $state.staticPage = action.payload;
    });
  },
});

export const { setPageTranslation } = systemSlice.actions;

export default systemSlice.reducer;
