import React, {
  ChangeEvent,
  useEffect, useMemo, useState
} from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import HelmetContainer from 'common/helmet';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import PopupConfirm from 'components/organisms/PopupConfirm';
import ShoppingCartTable, { ShoppingCartTableProps } from 'components/organisms/ShoppingCartTable';
import { getLocalStorage } from 'services/common/storage';
import { ShopDataTypes } from 'services/shops/types';
import {
  addConfirmList,
  checkAll, removeAllProduct, removeProductFromCart,
  updateBrand,
  updateProduct, updateProducts
} from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getShopsAction } from 'store/shops';
import { LOCAL_STORAGE_KEY } from 'utils/constant';
import { renderValue } from 'utils/functions';
import { getHomeLangURL, getURLCode } from 'utils/language';

interface OpenConfirmTypes {
  isOpen?: boolean,
  action?: () => void
}

const Cart: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { list } = useAppSelector((state) => state.cart);
  const { list: listShop } = useAppSelector((state) => state.shops);
  const taxPercent = useAppSelector((state) => state.systems.system?.others.tax) || 0;
  const exchangeRate = useAppSelector((state) => state.systems.system?.others.exchangeRate) || 0;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState<OpenConfirmTypes>({
    isOpen: false
  });

  const onUpdateToCart = async () => {
    const listOrderStorage = getLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER);
    if (listOrderStorage) {
      let shops: ShopDataTypes[];
      if (listShop.length === 0) {
        shops = await dispatch(getShopsAction({ limit: 50 })).unwrap();
      } else {
        shops = listShop;
      }
      dispatch(updateProducts({ shops, exchange: exchangeRate, taxPercent }));
    }
  };

  useEffect(() => {
    onUpdateToCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIncrease = (indexBrand: number, indexProduct: number) => {
    const brand = list[indexBrand];
    const product = brand.productList[indexProduct];
    const newProduct = {
      brand: {
        id: brand.brandId,
        tax: taxPercent,
        name: brand.brandName || '',
        discountPercent: brand.discountPercent || 0,
        totalDiscountCost: brand.totalDiscountCost,
        feeServicePercent: brand.feeServicePercent,
        feeServiceCost: brand.feeServiceCost,
      },
      product: {
        ...product,
        quantity: product.quantity + 1,
        total: (product.priceVND || 0) * (product.quantity + 1)
      }
    };
    dispatch(updateProduct(newProduct));
  };

  const handleDecrease = (indexBrand: number, indexProduct: number) => {
    const brand = list[indexBrand];
    const product = brand.productList[indexProduct];
    if (product.quantity === 1) {
      setOpenConfirm({
        isOpen: true,
        action: () => dispatch(removeProductFromCart(
          { brandId: brand.brandId, idProduct: product.id, taxPercent }
        ))
      });
      return;
    }
    const newProduct = {
      brand: {
        id: brand.brandId,
        tax: taxPercent,
        name: brand.brandName || '',
        discountPercent: brand.discountPercent || 0,
        totalDiscountCost: brand.totalDiscountCost,
        feeServicePercent: brand.feeServicePercent,
        feeServiceCost: brand.feeServiceCost,
      },
      product: {
        ...product,
        quantity: product.quantity - 1,
        total: (product.priceVND || 0) * (product.quantity - 1)
      }
    };
    dispatch(updateProduct(newProduct));
  };

  const handleOnchange = (
    e: ChangeEvent<HTMLInputElement>,
    indexBrand: number,
    indexProduct: number
  ) => {
    const brand = list[indexBrand];
    const product = brand.productList[indexProduct];
    const newProduct = {
      brand: {
        id: brand.brandId,
        tax: taxPercent,
        name: brand.brandName || '',
        discountPercent: brand.discountPercent || 0,
        totalDiscountCost: brand.totalDiscountCost,
        feeServicePercent: brand.feeServicePercent,
        feeServiceCost: brand.feeServiceCost,
      },
      product: {
        ...product,
        quantity: Number(e.target.value),
        total: (product.priceVND || 0) * Number(e.target.value)
      }
    };
    dispatch(updateProduct(newProduct));
  };

  const handleOnchangeNote = (
    e: ChangeEvent<HTMLInputElement>,
    indexBrand: number,
    indexProduct: number
  ) => {
    const brand = list[indexBrand];
    const product = brand.productList[indexProduct];
    const newProduct = {
      brand: {
        id: brand.brandId,
        tax: taxPercent,
        name: brand.brandName || '',
        discountPercent: brand.discountPercent || 0,
        totalDiscountCost: brand.totalDiscountCost,
        feeServicePercent: brand.feeServicePercent,
        feeServiceCost: brand.feeServiceCost,
      },
      product: {
        ...product,
        note: e.target.value,
      }
    };
    dispatch(updateProduct(newProduct));
  };

  const handleChecked = (indexBrand: number, indexProduct: number) => {
    const brand = list[indexBrand];
    const product = brand.productList[indexProduct];
    const newProduct = {
      brand: {
        id: brand.brandId,
        tax: taxPercent,
        name: brand.brandName || '',
        discountPercent: brand.discountPercent || 0,
        totalDiscountCost: brand.totalDiscountCost,
        feeServicePercent: brand.feeServicePercent,
        feeServiceCost: brand.feeServiceCost,
      },
      product: {
        ...product,
        isChecked: !product.isChecked,
      }
    };
    dispatch(updateProduct(newProduct));
  };

  const handleRemove = (indexBrand: number, indexProduct: number) => {
    const brand = list[indexBrand];
    const product = brand.productList[indexProduct];
    dispatch(removeProductFromCart({ brandId: brand.brandId, idProduct: product.id, taxPercent }));
  };

  const handleCheckedAll = (indexBrand: number) => {
    const brand = list[indexBrand];
    dispatch(checkAll({ brandId: brand.brandId, taxPercent }));
  };

  const handleRemoveAll = (indexBrand: number) => {
    const brand = list[indexBrand];
    dispatch(removeAllProduct({ brandId: brand.brandId }));
  };

  const handleBlurQuantity = (
    e: ChangeEvent<HTMLInputElement>,
    indexBrand: number,
    indexProduct: number
  ) => {
    const brand = list[indexBrand];
    const product = brand.productList[indexProduct];
    const newProduct = {
      brand: {
        id: brand.brandId,
        tax: taxPercent,
        name: brand.brandName || '',
        discountPercent: brand.discountPercent || 0,
        totalDiscountCost: brand.totalDiscountCost,
        feeServicePercent: brand.feeServicePercent,
        feeServiceCost: brand.feeServiceCost,
      },
      product
    };
    if (!e.target.value || !Number(e.target.value)) {
      setOpenConfirm({
        isOpen: true,
        action: () => {
          dispatch(removeProductFromCart({
            brandId: brand.brandId,
            idProduct: product.id,
            taxPercent
          }));
        }
      });
    } else {
      dispatch(updateProduct(newProduct));
    }
  };

  const handleOrder = (order: ShoppingCartTableProps) => {
    const brand = { ...order };
    brand.productList = order.productList.filter((item) => item.isChecked);
    dispatch(addConfirmList({ list: [brand], taxPercent }));
    navigate(getURLCode(i18n.language, 'REVIEW_ORDER'));
  };

  const handleChangeNoteBrand = (note: string, indexBrand: number) => {
    const brand = list[indexBrand];
    dispatch(updateBrand({ brandId: brand.brandId, noteBrand: note }));
  };

  const handleOrderAll = () => {
    const hasCheckedProduct = list.some((item) => item.productList.some((x) => x.isChecked));
    if (!hasCheckedProduct) {
      dispatch(addConfirmList({ list, taxPercent }));
    } else {
      const listBrand = list.reduce((prev, curr: ShoppingCartTableProps) => {
        const listCurrProductChecked = curr.productList.filter((x) => x.isChecked);
        if (listCurrProductChecked.length) {
          return [...prev, {
            ...curr,
            productList: listCurrProductChecked
          }];
        }
        return prev;
      }, [] as ShoppingCartTableProps[]);
      dispatch(addConfirmList({ list: listBrand, taxPercent }));
    }
    navigate(getURLCode(i18n.language, 'REVIEW_ORDER'));
  };

  const listOrders = useMemo(() => list?.map((item, index) => (
    {
      ...item,
      handleOrder: () => handleOrder(item),
      handleIncrease: (idx: number) => handleIncrease(index, idx),
      handleDecrease: (idx: number) => handleDecrease(index, idx),
      handleChangeNoteBrand: (note: string) => handleChangeNoteBrand(note, index),
      handleOnchange: (
        e: ChangeEvent<HTMLInputElement>,
        idx: number
      ) => handleOnchange(e, index, idx),
      handleOnchangeNote: (e: ChangeEvent<HTMLInputElement>, idx: number) => {
        handleOnchangeNote(e, index, idx);
      },
      handleChecked: (idx: number) => handleChecked(index, idx),
      handleRemove: (idx: number) => setOpenConfirm(
        {
          isOpen: true,
          action: () => handleRemove(index, idx)
        }
      ),
      handleCheckedAll: () => handleCheckedAll(index),
      handleRemoveAll: () => setOpenConfirm(
        {
          isOpen: true,
          action: () => handleRemoveAll(index)
        }
      ),
      handleBlurQuantity:
        (
          e: ChangeEvent<HTMLInputElement>,
          idx: number
        ) => handleBlurQuantity(e, index, idx),
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [list, taxPercent]);

  return (
    <div className="p-cart">
      <HelmetContainer />
      <Container>
        {
          listOrders && listOrders.length ? (
            <>
              {listOrders?.map((item, key) => (
                <div key={`item${key.toString()}`} className="p-cart_item">
                  <ShoppingCartTable {...item} />
                </div>
              ))}
              <div className="p-cart_total">
                <Text modifiers={['18x23', 'black']}>{t('payment.totalMoney')}</Text>
                <div className="p-cart_total-price">
                  <Text modifiers={['18x23', 'black', '700']}>{renderValue(list.reduce((prev, curr) => prev + Number(curr.finalTotal), 0), true)}</Text>
                </div>
              </div>
              <div className="p-cart_button">
                <Button onClick={handleOrderAll}>{t('cart.placeAllOrders')}</Button>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column align-items-center u-pt-30">
              <Icon iconName="cartBlack" size="55" />
              <div className="u-mt-20 u-mb-20">
                <Heading type="h2" modifiers={['center', '24x30']}>{t('cart.cartIsEmpty')}</Heading>
              </div>
              <div>
                <Link href={getHomeLangURL(i18n.language)}>
                  <Button>Shop now</Button>
                </Link>
              </div>
            </div>
          )
        }
      </Container>
      <PopupConfirm
        isOpen={openConfirm.isOpen}
        handleClose={() => setOpenConfirm({ isOpen: false })}
        title={<Heading type="h6" modifiers={['center', '600', 'sinopia', '24x30']}>{t('cart.confirm')}</Heading>}
        desc={t('cart.descConfirm').toString()}
        btnCancel={{
          label: t('cart.cancel'),
          onClick: () => setOpenConfirm({ isOpen: false })
        }}
        btnConfirm={{
          label: t('cart.confirm'),
          onClick: () => {
            setOpenConfirm({ isOpen: false });
            if (openConfirm.action) openConfirm.action();
          }
        }}
      />
    </div>
  );
};
export default Cart;
