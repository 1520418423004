import { MenuItemTypes } from 'services/menus/types';

const groupMenus = (menus?: MenuItemTypes[]) => {
  if (!menus) return undefined;
  const recursiveMenus = (
    menuList: MenuItemTypes[],
    parentId: number,
  ): MenuItemTypes[] => {
    const menusGrouped: MenuItemTypes[] = [];
    menuList.forEach((menu) => {
      if (menu.parentId === parentId) {
        const subMenus = recursiveMenus(menuList, menu.id);
        menusGrouped.push(
          subMenus.length > 0
            ? {
              ...menu,
              children: subMenus,
            }
            : {
              ...menu,
            },
        );
      }
    });
    return menusGrouped;
  };
  if (menus.length > 0) {
    const firstLevelParentId = menus.find((menu) => menu.depth === 1)!.parentId;
    return recursiveMenus(menus, firstLevelParentId);
  }
  return [];
};

export default groupMenus;
