import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Route, Routes
} from 'react-router-dom';
import './App.scss';

import MainLayout from 'common/MainLayout';
import Loading from 'components/atoms/Loading';
import useInitializeRender from 'hooks/useInitializeRender';
// import Frame from 'pages/Frame';
import useListenMessageAddToCart from 'hooks/useListenMessageAddToCart';
import useRoutesList from 'routes';
import { store } from 'store';
// import { CONSTANT_ROUTES, CONSTANT_ROUTES_EN } from 'utils/constant';

const App: React.FC = () => {
  const { isLoadingSystem } = useInitializeRender();
  useListenMessageAddToCart();
  const { routes } = useRoutesList();

  if (!routes && isLoadingSystem) return <Loading variant="fullScreen" />;

  return (
    <Suspense fallback={<Loading variant="fullScreen" />}>
      <Routes>
        {/* <Route path={`/${CONSTANT_ROUTES.SHOP}/:slug`} key="pages" element={<Frame />} />
        <Route path={`/en/${CONSTANT_ROUTES_EN.SHOP}/:slug`} key="pages" element={<Frame />} /> */}
        <Route
          path="/"
          element={(
            <MainLayout />
          )}
        >
          {(Object.keys(routes) as Array<keyof typeof routes>).map(
            (ele) => routes[ele].path.map((item, index) => (

              <Route
                key={`router-${ele}-${index.toString()}`}
                path={item}
                element={routes[ele].element}
              />
            )),
          )}
        </Route>
      </Routes>
    </Suspense>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  },
});

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
