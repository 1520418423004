import { MenusDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getMenusService = async (): Promise<MenusDataTypes[]> => {
  const res = await axiosInstance.get('menus');
  return res.data?.data;
};

export const Placeholder = '';
