import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import i18n from 'i18n';
import { MenuItemTypes } from 'services/menus/types';
import { checkExternalUrl } from 'utils/functions';
import { getHomeLangURL } from 'utils/language';

interface FooterProps {
  dataFooter?: MenuItemTypes[],
  imageList: string[],
  logo?: UploadImagePageTypes,
  infoCompany?: {
    nameCompany?: TextPageTypes,
    address?: TextPageTypes,
    licenseNo?: TextPageTypes,
  }
}

const Footer: React.FC<FooterProps> = ({
  dataFooter,
  imageList,
  logo,
  infoCompany,
}) => {
  const { t } = useTranslation();

  return (
    <div className="t-footer">
      <Container>
        <Row className="t-footer_top">
          <Col md={4}>
            <div className="t-footer_col_logo">
              <Link href={getHomeLangURL(i18n.language)}>
                <Image ratio="135x78" src={logo?.data?.path} alt={logo?.data?.alt} />
              </Link>
            </div>
            <div className="t-footer_col_info">
              {
                infoCompany?.nameCompany?.data && (
                  <Text modifiers={['600', '14x21', 'black', 'uppercase']}>{infoCompany?.nameCompany?.data}</Text>
                )
              }
              {
                infoCompany?.address?.data && (
                  <div className="t-footer_col_info-item">
                    <Text modifiers={['400', '14x21', 'black']}>{infoCompany?.address?.data}</Text>
                  </div>
                )
              }
              {
                infoCompany?.licenseNo?.data && (
                  <div className="t-footer_col_info-item">
                    <Text modifiers={['400', '14x21', 'black']}>{infoCompany?.licenseNo?.data}</Text>
                  </div>
                )
              }
            </div>
          </Col>
          <Col md={8}>
            <Row className="t-footer_col_right">
              {dataFooter && dataFooter.length > 0 && dataFooter.map((item, idx) => (
                <Col lg={3} sm={4} xs={6} key={`${idx.toString()}`} className="t-footer_col">
                  <div className="t-footer_subTitle">
                    <Text modifiers={['black', '500', '18x27', 'fontSanFranciscoDisplay']}>
                      {item.title}
                    </Text>
                  </div>
                  <ul>
                    {item?.children?.map((sub, index) => (
                      <li key={`link-${index.toString()}`}>
                        <Link
                          useExternal={checkExternalUrl(sub.reference?.slug || sub.link)}
                          href={sub.reference?.slug || sub.link}
                          customClassName="t-footer_link"
                        >
                          <Text modifiers={['black', '14x21', '500', 'fontSanFranciscoDisplay']}>
                            {sub.title}
                          </Text>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div className="t-footer_bottom">
          <Text modifiers={['black', '14x21', '600', 'fontSanFranciscoDisplay']} content={t('footer.via') || ''} />
          {
            imageList.map((item, key) => (
              <div className="t-footer_bottom-item" key={`image${key.toString()}`}>
                <Image ratio="1x1" src={item} size="contain" alt="Method payment" />
              </div>
            ))
          }
        </div>
      </Container>
    </div>
  );
};

Footer.defaultProps = {
};

export default Footer;
