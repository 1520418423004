import { CreateOrderDataTypes, CreateOrderParams, TrackingTypes } from './types';

import axiosInstance from 'services/common/instance';

export const postCreateOrderService = async (
  params: CreateOrderParams
): Promise<CreateOrderDataTypes> => {
  const res = await axiosInstance.post('orders', params);
  return res.data.data;
};

export const getTrackingService = async (params: CreateOrderDataTypes):
  Promise<TrackingTypes[]> => {
  const res = await axiosInstance.get('orders', { params });
  return res.data?.data;
};
