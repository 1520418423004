import React from 'react';

const Home = React.lazy(() => import('pages/Home'));
const Payment = React.lazy(() => import('pages/Payment'));
const TrackingPage = React.lazy(() => import('pages/TrackingPage'));
const ContentPage = React.lazy(() => import('pages/Content'));
const ContactPage = React.lazy(() => import('pages/Contact'));
const FaqCategoryPage = React.lazy(() => import('pages/FaqCategory'));
const TutorialPage = React.lazy(() => import('pages/Tutorial'));
const SearchShopPage = React.lazy(() => import('pages/SearchShop'));

export type TemplateCodeTypes = {
  templateCode: string;
  component: React.FC<BasePageDataTypes<any>>
};

export const TEMPLATE_CODE_CONFIG = {
  HOME_PAGE: 'HOME_PAGE',
  PAYMENT_PAGE: 'PAYMENT_PAGE',
  TRACKING_PAGE: 'TRACKING_PAGE',
  CONTENT_PAGE: 'CONTENT_PAGE',
  CONTACT_PAGE: 'CONTACT_PAGE',
  FAQ_CATEGORY_PAGE: 'FAQ_CATEGORY_PAGE',
  TUTORIAL_PAGE: 'TUTORIAL_PAGE',
  SEARCH_SHOP_PAGE: 'SEARCH_SHOP_PAGE'
};

export const TemplateCode: TemplateCodeTypes[] = [
  {
    templateCode: TEMPLATE_CODE_CONFIG.HOME_PAGE,
    component: Home,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.PAYMENT_PAGE,
    component: Payment,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.TRACKING_PAGE,
    component: TrackingPage,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.CONTENT_PAGE,
    component: ContentPage,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.CONTACT_PAGE,
    component: ContactPage,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.FAQ_CATEGORY_PAGE,
    component: FaqCategoryPage,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.TUTORIAL_PAGE,
    component: TutorialPage,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.SEARCH_SHOP_PAGE,
    component: SearchShopPage,
  },
];
