import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductItemTypes, ShoppingCartTableProps } from 'components/organisms/ShoppingCartTable';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'services/common/storage';
import { ShopDataTypes } from 'services/shops/types';
// eslint-disable-next-line import/no-cycle
import { LOCAL_STORAGE_KEY } from 'utils/constant';

export interface ProductAddCartTypes {
  brand: {
    name: string,
    tax: number,
    id: number,
    discountPercent: number,
    totalDiscountCost?: number
    feeServicePercent: number,
    feeServiceCost?: number,
  },
  product: ProductItemTypes
}

interface CartState {
  list: ShoppingCartTableProps[];
  confirmList: ShoppingCartTableProps[];
}

const initialState: CartState = {
  list: [],
  confirmList: [],
};

const calculatorBrand = (
  arr: ProductItemTypes[],
  taxPercent: number,
  feeServicePercent: number,
  discountPercent?: number,
  allowUnChecked?: boolean,
) => {
  const totalProduct = arr?.reduce((
    prev,
    curr
  ) => ((curr.isChecked || allowUnChecked) ? prev + Number(curr.total) : prev), 0);
  const tax = totalProduct * taxPercent / 100;
  const totalDiscount = discountPercent ? totalProduct * discountPercent / 100 : 0;
  const totalAfterTax = totalProduct + tax - totalDiscount;
  const feeService = feeServicePercent > 0
    ? totalAfterTax * feeServicePercent / 100 : 0;
  const totalFinal = totalAfterTax + feeService;
  return {
    totalProduct,
    tax,
    totalDiscount,
    totalAfterTax,
    feeService,
    totalFinal
  };
};

const updateBrandData = (
  brand: ShoppingCartTableProps,
  taxPercent: number,
  allowUnChecked?: boolean
) => ({
  totalPrice: calculatorBrand(
    brand.productList,
    taxPercent,
    brand.feeServicePercent,
    brand.discountPercent,
    allowUnChecked
  ).totalProduct,
  tax: calculatorBrand(
    brand.productList,
    taxPercent,
    brand.feeServicePercent,
    brand.discountPercent,
    allowUnChecked
  ).tax,
  // totalAfterTax: calculatorBrand(
  //   brand.productList,
  //   taxPercent,
  //   brand.feeServicePercent,
  //   brand.discountPercent,
  //   allowUnChecked
  // ).totalAfterTax,
  feeServiceCost: calculatorBrand(
    brand.productList,
    taxPercent,
    brand.feeServicePercent,
    brand.discountPercent,
    allowUnChecked
  ).feeService,
  finalTotal: calculatorBrand(
    brand.productList,
    taxPercent,
    brand.feeServicePercent,
    brand.discountPercent,
    allowUnChecked
  ).totalFinal,
  totalDiscountCost: calculatorBrand(
    brand.productList,
    taxPercent,
    brand.feeServicePercent,
    brand.discountPercent,
    allowUnChecked
  ).totalDiscount,
  discountPercent: brand.discountPercent
});

export const cartSlice = createSlice({
  name: 'cartReducer',
  initialState,
  reducers: {
    initCart($state) {
      const listOrderStorage = getLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER);
      if (listOrderStorage) {
        $state.list = JSON.parse(listOrderStorage);
      }
    },
    initConfirmCart($state) {
      const listOrderStorage = getLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER_CONFIRM);
      if (listOrderStorage) {
        $state.confirmList = JSON.parse(listOrderStorage);
      }
    },
    clearWhenCheckout($state, action: PayloadAction<{ tax: number }>) {
      const { tax } = action.payload;
      const { confirmList } = $state;
      const cartLists = $state.list.reduce((result, cart) => {
        const validBrand = confirmList.find((x) => x.brandId === cart.brandId);
        if (validBrand) {
          const filteredProduct = cart.productList.filter(
            (product) => !validBrand.productList.some((x) => x.id === product.id)
          );
          if (filteredProduct.length > 0) {
            result.push({
              ...cart,
              ...updateBrandData({ ...cart, productList: filteredProduct }, tax),
              productList: filteredProduct
            });
          }
        } else {
          result.push(cart);
        }
        return result;
      }, [] as ShoppingCartTableProps[]);
      $state.list = cartLists;
      setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(cartLists));
      $state.confirmList = [];
      removeLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER_CONFIRM);
    },
    addToCart($state, action: PayloadAction<ProductAddCartTypes>) {
      const currentLists = [...$state.list];
      const { brand, product } = action.payload;
      const excitedBrandIndex = currentLists.findIndex((item) => item.brandId === brand.id);
      let excitedBrand = { ...currentLists[excitedBrandIndex] };
      if (excitedBrandIndex >= 0) {
        const excitedProduct = excitedBrand.productList.find((item) => item.id === product.id);
        if (excitedProduct) {
          excitedProduct.quantity += product.quantity;
          excitedProduct.total = excitedProduct.quantity * Number(product.priceVND);
          excitedBrand = {
            ...excitedBrand,
            ...updateBrandData(excitedBrand, brand.tax),
          };
        } else {
          excitedBrand.productList.push(product);
          excitedBrand = {
            ...excitedBrand,
            ...updateBrandData(excitedBrand, brand.tax),
          };
        }
        currentLists[excitedBrandIndex] = excitedBrand;
      } else {
        currentLists.push({
          brandName: brand.name,
          brandId: brand.id,
          totalPrice: 0,
          tax: brand.tax,
          finalTotal: 0,
          totalDiscountCost: brand.totalDiscountCost,
          discountPercent: brand.discountPercent,
          productList: [product],
          feeServicePercent: brand.feeServicePercent,
          feeServiceCost: brand.feeServiceCost
        });
      }
      setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(currentLists));
      $state.list = currentLists;
    },
    updateProduct($state, action: PayloadAction<ProductAddCartTypes>) {
      const { list } = $state;
      const { brand, product } = action.payload;
      const currentLists = [...list];
      const excitedBrandIndex = list.findIndex((item) => item.brandId === brand.id);
      if (excitedBrandIndex >= 0) {
        let excitedBrand = { ...currentLists[excitedBrandIndex] };
        const excitedProductIndex = excitedBrand.productList.findIndex(
          (item) => item.id === product.id
        );
        if (excitedProductIndex >= 0) {
          excitedBrand.productList[excitedProductIndex] = product;
          excitedBrand = {
            ...excitedBrand,
            ...updateBrandData(excitedBrand, brand.tax)
          };
          currentLists[excitedBrandIndex] = excitedBrand;
        }
      }
      setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(currentLists));
      $state.list = currentLists;
    },
    updateBrand($state, action: PayloadAction<{ brandId: number, noteBrand?: string }>) {
      const { list } = $state;
      const { noteBrand, brandId } = action.payload;
      const excitedBrand = list.find((item) => item.brandId === brandId);
      if (excitedBrand) {
        excitedBrand.note = noteBrand;
        setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(list));
      }
    },
    checkAll($state, action: PayloadAction<{ brandId: number, taxPercent: number }>) {
      const { list } = $state;
      const { brandId, taxPercent } = action.payload;
      const excitedBrand = list.find((item) => item.brandId === brandId);
      if (excitedBrand) {
        const haveAnyUnChecked = excitedBrand.productList.some((item) => !item.isChecked);
        excitedBrand.productList = excitedBrand.productList.map(
          (item) => ({
            ...item,
            isChecked: haveAnyUnChecked
          })
        );
        excitedBrand.totalPrice = updateBrandData(excitedBrand, taxPercent).totalPrice;
        excitedBrand.tax = updateBrandData(excitedBrand, taxPercent).tax;
        excitedBrand.finalTotal = updateBrandData(excitedBrand, taxPercent).finalTotal;
        excitedBrand.totalDiscountCost = updateBrandData(
          excitedBrand,
          taxPercent
        ).totalDiscountCost;
        excitedBrand.feeServiceCost = updateBrandData(
          excitedBrand,
          taxPercent
        ).feeServiceCost;
        setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(list));
      }
    },
    removeAllProduct($state, action: PayloadAction<{ brandId: number }>) {
      const { list } = $state;
      const { brandId } = action.payload;
      const currentLists = [...list];
      const excitedBrandIndex = list.findIndex((item) => item.brandId === brandId);
      if (excitedBrandIndex >= 0) {
        currentLists.splice(excitedBrandIndex, 1);
      }
      setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(currentLists));
      $state.list = currentLists;
    },
    removeProductFromCart(
      $state,
      action: PayloadAction<{ brandId: number, idProduct?: number, taxPercent: number }>
    ) {
      const { list } = $state;
      const { brandId, idProduct, taxPercent } = action.payload;
      let currentLists = [...list];
      const excitedBrand = list.find((item) => item.brandId === brandId);
      if (excitedBrand) {
        const productLists = [...excitedBrand.productList];
        excitedBrand.productList = productLists.filter((item) => item.id !== idProduct);
        excitedBrand.totalPrice = updateBrandData(excitedBrand, taxPercent).totalPrice;
        excitedBrand.tax = updateBrandData(excitedBrand, taxPercent).tax;
        excitedBrand.finalTotal = updateBrandData(excitedBrand, taxPercent).finalTotal;
        if (!excitedBrand.productList.length) {
          currentLists = currentLists.filter((item) => item.brandId !== brandId);
        }
      }
      setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(currentLists));
      $state.list = currentLists;
    },
    addConfirmList(
      $state,
      action: PayloadAction<{ list: ShoppingCartTableProps[]; taxPercent: number }>
    ) {
      const { list, taxPercent } = action.payload;
      const listBrand = list.map((brand) => ({
        ...brand,
        ...updateBrandData(brand, taxPercent, true)
      }));
      setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER_CONFIRM, JSON.stringify(listBrand));
      $state.confirmList = listBrand;
    },
    updateProducts($state, action: PayloadAction<{
      shops: ShopDataTypes[], exchange: number, taxPercent: number
    }>) {
      const { shops, taxPercent } = action.payload;
      const listOrderStorage = getLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER);

      if (listOrderStorage) {
        const listOrder = [...JSON.parse(listOrderStorage)] as ShoppingCartTableProps[];
        const updateListOrder = listOrder.map((order) => {
          const feeServicePercentLatest = shops.find((item) => item.shopData.id === order.brandId)
            ?.shopData.serviceFee || 0;
          const calBrand = calculatorBrand(
            order.productList,
            taxPercent,
            feeServicePercentLatest,
            order.discountPercent
          );
          return {
            ...order,
            feeServiceCost: calBrand.feeService,
            feeServicePercent: feeServicePercentLatest,
            finalTotal: calBrand.totalFinal,
          };
        });

        setLocalStorage(LOCAL_STORAGE_KEY.SD_ORDER, JSON.stringify(updateListOrder));
        $state.list = updateListOrder;
      }
    },
  },
  extraReducers() { },
});

export const {
  initCart,
  initConfirmCart,
  clearWhenCheckout,
  addToCart,
  updateProduct,
  removeProductFromCart,
  checkAll,
  removeAllProduct,
  addConfirmList,
  updateBrand,
  updateProducts
} = cartSlice.actions;

export default cartSlice.reducer;
