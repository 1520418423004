import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getMenusService } from 'services/menus';
import { MenusDataTypes } from 'services/menus/types';

interface MenusState {
  list: MenusDataTypes[];
}

const initialState: MenusState = {
  list: [],
};

export const getMenusAction = createAsyncThunk<
  MenusDataTypes[],
  void,
  { rejectValue: ErrorResponse }
>('menusReducer/getMenusAction', async (_, { rejectWithValue }) => {
  try {
    return await getMenusService();
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const menusSlice = createSlice({
  name: 'menusReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getMenusAction.fulfilled, ($state, action) => {
      $state.list = action.payload;
    });
  },
});

export default menusSlice.reducer;
