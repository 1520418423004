/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { renderValue } from 'utils/functions';

export type ProductItemTypes = {
  id: number,
  thumbnail?: string;
  name?: string;
  note?: string;
  price?: number;
  priceVND?: number;
  quantity: number;
  total?: number;
  isChecked?: boolean;
  link?: string;
};
export interface ShoppingCartTableProps {
  brandName?: string;
  brandId: number;
  productList: ProductItemTypes[];
  totalPrice?: number;
  tax: number;
  finalTotal?: number;
  isConfirm?: boolean;
  note?: string;
  discountPercent?: number;
  feeServicePercent: number;
  feeServiceCost?: number;
  totalDiscountCost?: number
  handleChangeNoteBrand?: (note: string) => void;
  handleOrder?: (note?: string) => void;
  handleChecked?: (idx: number) => void;
  handleCheckedAll?: () => void;
  handleRemove?: (idx: number) => void;
  handleRemoveAll?: () => void;
  handleIncrease?: (idx: number) => void;
  handleDecrease?: (idx: number) => void;
  handleOnchange?: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
  handleOnchangeNote?: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
  handleBlurQuantity?: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
}

const ShoppingCartTable: React.FC<ShoppingCartTableProps> = ({
  brandName,
  productList,
  totalPrice,
  tax,
  finalTotal,
  isConfirm,
  discountPercent,
  feeServicePercent,
  feeServiceCost,
  totalDiscountCost,
  handleOrder,
  handleChecked,
  handleCheckedAll,
  handleRemove,
  handleRemoveAll,
  handleIncrease,
  handleDecrease,
  handleOnchange,
  handleOnchangeNote,
  handleBlurQuantity,
  handleChangeNoteBrand,
  note,
  brandId,
}) => {
  const { t } = useTranslation();

  return (
    <div className="o-shoppingCartTable">
      <div className="o-shoppingCartTable_left">
        <div className="o-shoppingCartTable_left_header">
          <Text modifiers={['20x23', '700', 'uppercase']}>{brandName}</Text>
          {!isConfirm && (
            <button className="o-shoppingCartTable_iconButton" type="button" onClick={() => handleRemoveAll && handleRemoveAll()}>
              <Icon iconName="recycleBin" size="21x27" />
            </button>
          )}
        </div>
        {productList?.length > 0 && (
          <div className="o-shoppingCartTable_left_table">
            <table>
              <thead>
                <tr>
                  <th>
                    {
                      !isConfirm && (
                        <Checkbox
                          checked={productList?.every((item) => item.isChecked)}
                          onChange={() => handleCheckedAll && handleCheckedAll()}
                        />
                      )
                    }
                  </th>
                  <th>
                    <Text modifiers={['16x19']}>
                      {t('cart.ProductName')}
                    </Text>
                  </th>
                  <th>
                    <Text modifiers={['16x19']}>
                      {t('cart.cost')}
                    </Text>
                  </th>
                  <th>
                    <Text modifiers={['16x19']}>
                      {t('cart.priceVND')}
                    </Text>
                  </th>
                  <th>
                    <Text modifiers={['16x19']}>
                      {t('cart.quantity')}
                    </Text>
                  </th>
                  <th>
                    <Text modifiers={['16x19']}>
                      {t('cart.amount')}
                    </Text>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {productList?.map((item, idx) => (
                  <tr key={`table-cell-${brandId}- ${idx.toString()}`}>
                    <td>
                      {
                        !isConfirm && (
                          <Checkbox
                            checked={item.isChecked}
                            onChange={() => handleChecked && handleChecked(idx)}
                          />
                        )
                      }
                    </td>
                    <td className="o-shoppingCartTable_productItem">
                      <div className="o-shoppingCartTable_productItem_img">
                        <Image src={item.thumbnail} ratio="1x1" alt="" />
                      </div>
                      <div className="o-shoppingCartTable_productItem_info">
                        <Text modifiers={['16x19', '700']}>{item.name}</Text>
                        <div className="o-shoppingCartTable_productItem_info-option">
                          <Input
                            value={item.note}
                            disabled={isConfirm}
                            onChange={(e) => handleOnchangeNote && handleOnchangeNote(e, idx)}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <Text modifiers={['14x22']}>{renderValue(item.price, true, 'USD')}</Text>
                    </td>
                    <td>
                      <Text modifiers={['14x22']}>{renderValue(item.priceVND, true)}</Text>
                    </td>
                    <td className="o-shoppingCartTable_quantity">
                      <Input
                        type={!isConfirm ? 'number' : 'text'}
                        disabled={isConfirm}
                        value={productList[idx]?.quantity?.toString()}
                        handleIncrease={() => handleIncrease && handleIncrease(idx)}
                        handleDecrease={() => handleDecrease && handleDecrease(idx)}
                        onChange={(e) => handleOnchange && handleOnchange(e, idx)}
                        onBlur={(e) => handleBlurQuantity && handleBlurQuantity(e, idx)}
                      />
                    </td>
                    <td>
                      <Text modifiers={['14x22']}>{renderValue(item.total, true)}</Text>
                    </td>
                    <td>
                      {!isConfirm && (
                        <button
                          type="button"
                          className="o-shoppingCartTable_iconButton"
                          onClick={() => handleRemove && handleRemove(idx)}
                        >
                          <Icon iconName="recycleBin" size="14x18" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="o-shoppingCartTable_right">
        <div>
          <Text modifiers={['18x23', '700', 'uppercase']}>{t('cart.order')}</Text>
        </div>
        <textarea
          rows={3}
          placeholder={t('checkout.note').toString()}
          value={note}
          disabled={isConfirm}
          onChange={(e) => handleChangeNoteBrand && handleChangeNoteBrand(e.target.value)}
        />
        <div className="o-shoppingCartTable_right_extra">
          <Text modifiers={['16x19']}>
            {t('payment.totalMoney')}
            :
          </Text>
          <Text modifiers={['16x19']}>{totalPrice && renderValue(totalPrice, true)}</Text>
        </div>
        <div className="o-shoppingCartTable_right_extra">
          <Text modifiers={['16x19']}>
            {t('payment.tax')}
            :
          </Text>
          <Text modifiers={['16x19']}>{tax && totalPrice ? renderValue(tax, true) : 0}</Text>
        </div>
        {
          Number(discountPercent) > 0 && (
            <div className="o-shoppingCartTable_right_extra">
              <Text modifiers={['16x19']}>
                {`${t('payment.discount')} (${discountPercent}%)`}
                :
              </Text>
              <Text modifiers={['16x19']}>{totalDiscountCost ? `-${renderValue(totalDiscountCost, true)}` : totalDiscountCost}</Text>
            </div>
          )
        }
        {
          Number(feeServicePercent) > 0 && (
            <div className="o-shoppingCartTable_right_extra">
              <Text modifiers={['16x19']}>
                {`${t('payment.feeService')} (${feeServicePercent}%)`}
                :
              </Text>
              <Text modifiers={['16x19']}>{renderValue(feeServiceCost, true)}</Text>
            </div>
          )
        }
        <div className="o-shoppingCartTable_right_extra">
          <Text modifiers={['16x19']}>
            {t('cart.total')}
            :
          </Text>
          <Text modifiers={['16x19', '700']}>{finalTotal && renderValue(finalTotal, true)}</Text>
        </div>
        {!isConfirm && (
          <Button
            disabled={!productList?.some((item) => item.isChecked)}
            onClick={() => handleOrder && handleOrder(note)}
          >
            {t('cart.order')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ShoppingCartTable;
