import axiosInstance from 'services/common/instance';

export const getPageService = async (
  slug?: string,
): Promise<BasePageDataTypes<any>> => {
  const response = await axiosInstance.get(`pages/${slug}`);
  return response.data.data;
};

export const placeholder = {};
