import React from 'react';

import CustomModal from '../Modal';

import Button from 'components/atoms/Button';
import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

export interface PopupConfirmProps {
  isOpen?: boolean;
  title?: React.ReactNode;
  desc?: string;
  iconName?: IconName;
  iconSize?: IconSize;
  handleClose?: () => void;
  btnConfirm?: {
    label: string;
    onClick: () => void;
  },
  btnCancel?: {
    label: string;
    onClick: () => void;
  },
  isCheckoutPage?: boolean;
}

const PopupConfirm: React.FC<PopupConfirmProps> = ({
  isOpen = false,
  title,
  desc,
  iconSize = '55',
  iconName = 'warning',
  btnCancel,
  btnConfirm,
  isCheckoutPage,
  handleClose
}) => (
  <CustomModal variant="maxWith428" isOpen={isOpen} showIconClose={!!handleClose} handleClose={handleClose}>
    <div className="o-popupConfirm">
      <Icon iconName={iconName} size={iconSize} />
      {
        title && (
          <div className="u-mt-12">
            {title}
          </div>
        )
      }
      {
        desc && (
          <div className="u-mt-8 u-pl-lg-20 u-pr-lg-20 u-pl-10 u-pr-10">
            <Text modifiers={['center', '16x27']}>
              {desc}
            </Text>
          </div>
        )
      }
      <div className="d-flex justify-content-center' u-mt-24 o-popupConfirm_btn">
        {
          btnCancel && (
            <Button onClick={btnCancel.onClick}>
              {btnCancel.label}
            </Button>
          )
        }
        {btnConfirm && (
          <Button modifier={isCheckoutPage ? [] : ['outline']} onClick={btnConfirm.onClick}>
            {btnConfirm.label}
          </Button>
        )}
      </div>
    </div>
  </CustomModal>
);

PopupConfirm.defaultProps = {
};

export default PopupConfirm;
