import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import RenderPage from '.';

import Loading from 'components/atoms/Loading';
import { getPageService } from 'services/pages';
import { useAppDispatch } from 'store/hooks';
import { setPageTranslation } from 'store/systems';

const PageNavigation: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const dispatch = useAppDispatch();

  const { data, isLoading, isError } = useQuery(
    ['getDataPage', slug],
    () => getPageService(slug),
    {
      enabled: !!slug
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(setPageTranslation(data.translations));
    }
  }, [data, dispatch]);

  if (isLoading) {
    return <Loading variant="fullScreen" />;
  }

  if (isError) return null; // TODO: Implement Error

  return (
    <RenderPage data={data} />
  );
};

export default PageNavigation;
