import React from 'react';

import mapModifiers from 'utils/functions';

type Sizes = '12x16' | '14x20' | '14x22' | '14x21' | '14x29' | '16x24' | '16x19' | '16x27' | '18x27' | '18x23' | '18x24' | '20x23' | '22x27' | '24x29';

export type TextStyle = (GeneralTextStyle | Sizes | ColorStyle | FontFamily)[];

interface TextProps extends React.HtmlHTMLAttributes<HTMLParagraphElement> {
  modifiers?: TextStyle;
  type?: 'p' | 'span' | 'div';
  content?: string;
  children?: React.ReactNode;
}

const Text: React.FC<TextProps> = ({
  modifiers,
  type = 'p',
  content,
  children,
  ...props
}) => {
  const Element = type;
  return content ? (
    <Element
      {...props}
      className={mapModifiers('a-text', modifiers)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  ) : (
    <Element {...props} className={mapModifiers('a-text', modifiers)}>
      {children}
    </Element>
  );
};

Text.defaultProps = {
  modifiers: undefined,
  content: undefined,
};

export default React.memo(Text);
