import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getShopService } from 'services/shops';
import { ShopDataTypes } from 'services/shops/types';

interface ShopsState {
  list: ShopDataTypes[];
}

const initialState: ShopsState = {
  list: [],
};

export const getShopsAction = createAsyncThunk(
  'ShopsReducer/getShopsAction',
  async (params: BaseRequestParamTypes, { rejectWithValue }) => {
    try {
      const shops = await getShopService(params);
      return shops.data;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const shopsSlice = createSlice({
  name: 'ShopsReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getShopsAction.fulfilled, ($state, action) => {
      $state.list = action.payload;
    });
  },
});

export default shopsSlice.reducer;
