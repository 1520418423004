import React from 'react';

import Icon from '../Icon';

import mapModifiers from 'utils/functions';

interface LoadingProps {
  variant?: 'fullScreen' | 'block';
}

const Loading: React.FC<LoadingProps> = ({
  variant
}) => (
  <div className={mapModifiers('a-loading', variant)}>
    <Icon iconName="loading" size="200" />
  </div>
);

Loading.defaultProps = {
  variant: 'fullScreen',
};

export default Loading;
