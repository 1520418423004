/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from 'react';

import loadingGift from 'assets/icons/ic_loading_button.gif';
import mapModifiers from 'utils/functions';

type Size = 'sm' | 'lg' | 'xs';
type Variant = 'white' | 'outline' | 'vividTangelo' | 'orange' | 'eff2';
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  modifier?: (Size | Variant | 'no-radius' | 'no-padding')[];
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children, modifier, loading, ...props
}) => (
  <button type={props.type || 'button'} className={mapModifiers('a-button', modifier, loading && 'loading')} {...props}>
    {
      loading ? (
        <div className="a-button_loading">
          <img src={loadingGift} alt="loading" />
        </div>
      )
        : children
    }
  </button>
);

Button.defaultProps = {
  children: undefined,
};

export default Button;
