/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import mapModifiers from 'utils/functions';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  ratio: Ratio;
  srcTablet?: string;
  srcMobile?: string;
  size?: 'contain' | 'cover' | 'fill';
}

const Image: React.FC<ImageProps> = ({
  ratio,
  size,
  srcTablet,
  srcMobile,
  ...props
}) => (
  <picture className={mapModifiers('a-image', ratio, size)}>
    <source media="(max-width:992px)" srcSet={srcTablet || props.src} />
    <source media="(max-width:576px)" srcSet={srcMobile || props.src} />
    <img {...props} />
  </picture>
);

Image.defaultProps = {
  loading: 'lazy',
  size: 'cover'
};

export default Image;
