import { useCallback, useEffect } from 'react';

import { addToCart } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getShopsAction } from 'store/shops';

export interface AddCartTypes {
  price: number;
  name: string;
  quantity: number;
  note?: string;
  link?: string;
  image?: string;
  originHref?: string;
}

const useListenMessageAddToCart = () => {
  // Store
  const exchangeRate = useAppSelector((state) => state.systems.system?.others.exchangeRate);
  const taxPercent = useAppSelector((state) => state.systems.system?.others.tax || 0);
  const { list: listOrder } = useAppSelector((state) => state.cart);
  const { list: listShop } = useAppSelector((state) => state.shops);

  // Dispatch
  const dispatch = useAppDispatch();

  const onAddToCart = useCallback(async (data: AddCartTypes) => {
    if (!data.quantity) return;
    let shops;
    if (listShop.length === 0) {
      shops = await dispatch(getShopsAction({ limit: 50 })).unwrap();
    } else {
      shops = listShop;
    }
    const shopAddToCard = shops.find((shop) => shop.shopData.url.includes(data.originHref || ''));
    if (shopAddToCard && shops && shops.length > 0) {
      const excitedShop = listOrder.find((order) => order.brandId === shopAddToCard.shopData.id);
      const itemAdd = {
        brand: {
          id: shopAddToCard?.shopData.id,
          tax: taxPercent,
          name: shopAddToCard?.shopData?.name,
          discountPercent: shopAddToCard.shopData.discount,
          feeServicePercent: shopAddToCard.shopData.serviceFee || 0,
          totalDiscountCost: 0
        },
        product: {
          id: (!excitedShop || !excitedShop.productList || !excitedShop.productList.length)
            ? 1 : ((excitedShop.productList?.[excitedShop.productList.length - 1]?.id || 0) + 1),
          ...data,
          priceVND: (data.price && exchangeRate) ? data.price * exchangeRate : 0,
          thumbnail: data?.image,
          quantity: data.quantity,
          total: (data?.price && exchangeRate)
            ? data.price * exchangeRate * data.quantity : 0,
          link: data.link,
          note: data.note
        }
      };
      dispatch(addToCart(itemAdd));
    }
  }, [dispatch, exchangeRate, listOrder, taxPercent, listShop]);

  useEffect(() => {
    const handleMessage = async (event: any) => {
      try {
        if (!event.data) return;
        if (event.data.type === 'addToCart') {
          onAddToCart({
            ...event.data.body,
            originHref: event.origin
          });
        }
      } catch (e) {
        console.log('Receive message error: ', e);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onAddToCart]);
};

export default useListenMessageAddToCart;
