/* eslint-disable */
import React, {
  useCallback, useMemo, useRef, useState
} from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import searchIcon from 'assets/icons/ic_search_black.svg';
import { TEMPLATE_CODE_CONFIG } from 'common/Navigations/constants';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Collapse from 'components/molecules/Collapse';
import { OptionType } from 'components/molecules/Pulldown';
import useClickOutside from 'hooks/useClickOutside';
import useDeviceQueries from 'hooks/useDeviceQueries';
import useWindowScroll from 'hooks/useWindowScroll';
import { MenuItemTypes } from 'services/menus/types';
import { useAppSelector } from 'store/hooks';
import mapModifiers from 'utils/functions';
import { concatLangWithSlug, getHomeLangURL } from 'utils/language';

interface PullDownProps {
  options: OptionType[];
  selected: OptionType;
  handleSelected?: (val: OptionType) => void;
}

const Pulldown: React.FC<PullDownProps> = ({ options, selected, handleSelected }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useClickOutside(contentRef, () => setOpen(false));

  const customSelected = (val: OptionType) => {
    setOpen(false);
    if (handleSelected) handleSelected(val);
  };
  return (
    <div ref={contentRef} className={mapModifiers('t-header_pulldown', open && 'open')}>
      <div
        onClick={() => setOpen(!open)}
        className="t-header_pulldown-header d-flex align-items-center"
      >
        <div className="u-mr-5">
          <Text modifiers={['black', '12x16']}>{selected?.label || options?.[0]?.label}</Text>
        </div>
        <Icon iconName="arrowDown" size="12" />
      </div>
      <div className="t-header_pulldown-content">
        <ul>
          {
            options?.map((option, index) => (
              <li
                key={`pulldown-li-${index.toString()}`}
                onClick={() => customSelected(option)}
              >
                <div className={mapModifiers('icon-check', option?.value === selected?.value && 'active')}>
                  <Icon iconName="checkWhite" size="14" />
                </div>
                {option?.label}
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

interface MenuProps {
  listMenus: MenuItemTypes[];
  isSubmenu?: boolean;
}

const Menu: React.FC<MenuProps> = ({ listMenus, isSubmenu }) => {
  const { pathname } = useLocation();

  const findActiveIds = useCallback((arr: MenuItemTypes[], activeIds: number[] = []) => {
    arr.forEach((item) => {
      if (item.children) {
        const childActiveIds = findActiveIds(item.children);
        if (childActiveIds.length > 0) {
          activeIds.push(item.id);
          activeIds.push(...childActiveIds);
        }
      } else if (item.link === (pathname)) {
        activeIds.push(item.id);
      }
    });
    return activeIds;
  }, [pathname]);

  const active = useMemo(() => findActiveIds(listMenus), [listMenus, findActiveIds]);

  return (
    <div className={mapModifiers('t-header_menu', isSubmenu && 'submenu')}>
      {
        listMenus?.map((menu, index) => (
          <div
            key={`header-menu-${index.toString()}`}
            className="t-header_menu-item"
          >
            <Link
              href={menu?.reference?.slug || menu.link}
              customClassName="t-header_menu-icon"
            >
              <Icon iconName={index < 1 ? 'tracking' : 'guide'} size="16" />
            </Link>
            {
              !menu?.children || !menu?.children.length
                ? (
                  <div className={mapModifiers('t-header_menu-header', active.includes(menu.id) && 'active')}>
                    <Text modifiers={['14x21', isSubmenu ? '600' : '500', 'fontSanFranciscoDisplay']}>
                      <Link href={menu?.reference?.slug || menu.link}>
                        {menu?.title}
                      </Link>
                    </Text>
                  </div>
                )
                : (
                  <div>
                    <div className={mapModifiers('t-header_menu-header', !!menu.parentId && 'submenu', active.includes(menu.id) && 'active')}>
                      <Text modifiers={['14x21', isSubmenu ? '600' : '500', 'fontSanFranciscoDisplay']}>
                        {menu?.title}
                      </Text>
                      <div className="u-ml-5">
                        <Icon iconName="arrowDown" size="14" />
                      </div>
                    </div>
                    <div className={mapModifiers('t-header_menu-popup', isSubmenu && 'submenu')}>
                      {
                        menu?.children?.map((child, idx) => (child.children
                          ? (
                            <div
                              key={`menu-child-${child.link?.toString()} - ${idx.toString()}`}
                            >
                              <Menu listMenus={[child]} isSubmenu={!!child.parentId} />
                            </div>
                          )
                          : (
                            <div
                              className={mapModifiers('t-header_menu-popup-item', active.includes(child.id) && 'active')}
                              key={`menu-item-${child.link?.toString()} - ${idx.toString()}`}
                            >
                              <Link href={child?.reference?.slug || child.link}>
                                <Text>{child.title}</Text>
                              </Link>
                            </div>
                          )))
                      }
                    </div>
                  </div>
                )
            }
          </div>
        ))
      }
    </div>
  );
};

const MenuMobile: React.FC<MenuProps> = ({ listMenus, isSubmenu }) => {
  const { pathname } = useLocation();

  const findActiveIds = useCallback((arr: MenuItemTypes[], activeIds: number[] = []) => {
    arr.forEach((item) => {
      if (item.children) {
        const childActiveIds = findActiveIds(item.children);
        if (childActiveIds.length > 0) {
          activeIds.push(item.id);
          activeIds.push(...childActiveIds);
        }
      } else if (item.link === pathname) {
        activeIds.push(item.id);
      }
    });
    return activeIds;
  }, [pathname]);

  const active = useMemo(() => findActiveIds(listMenus), [listMenus, findActiveIds]);

  return (
    <div className={mapModifiers('t-header_menuMobile', isSubmenu && 'submenu')}>
      {
        listMenus?.map((menu, index) => (
          <div
            key={`header-menu-${index.toString()}`}

          >
            {
              !menu?.children || !menu?.children.length
                ? (
                  <div className={mapModifiers('t-header_menuMobile-item', active.includes(menu.id) && 'active')}>
                    <Text modifiers={['14x20', 'yankeesBlue', '500']}>
                      <Link href={menu.reference?.slug || menu?.link}>
                        {menu?.title}
                      </Link>
                    </Text>
                  </div>
                )
                : (
                  <div className={mapModifiers('t-header_menuMobile-itemCollapse', active.includes(menu.id) && 'active')}>
                    <Collapse title={menu.title}>
                      {
                        menu?.children?.map((child, idx) => (child.children
                          ? (
                            <MenuMobile key={`menu-child-${child.link?.toString()} - ${idx.toString()}`} listMenus={[child]} isSubmenu={!!child.parentId} />
                          )
                          : (
                            <div
                              className={mapModifiers('t-header_menuMobile-colItem', active.includes(child.id) && 'active')}
                              key={`menu-item-${child.link?.toString()} - ${idx.toString()}`}
                            >
                              <Link href={child.link}>
                                <Text modifiers={['14x20', 'yankeesBlue', '500']}>{child.title}</Text>
                              </Link>
                            </div>
                          )))
                      }
                    </Collapse>
                  </div>
                )
            }
          </div>
        ))
      }
    </div>
  );
};

interface HeaderProps {
  hotLine?: string;
  languageOptions: OptionType[];
  languageSelected?: OptionType;
  handleChangeLanguage?: (val: OptionType) => void;
  // handleChangeCoin?: (val: OptionType) => void;
  handlePressCart?: () => void;
  // coinOptions: OptionType[];
  listMenus: MenuItemTypes[];
  numberCart?: number;
  textTrackingOrder?: string;
  iconTrackingOrder?: string;
  iconLanguage?: string;
  // iconCurrency?: string;
  iconHotline?: string;
  handleClickTracking?: () => void;
  logo?: UploadImagePageTypes;
}

const Header: React.FC<HeaderProps> = ({
  hotLine,
  languageOptions,
  listMenus,
  numberCart,
  // handleChangeCoin,
  handleChangeLanguage,
  handlePressCart,
  textTrackingOrder,
  iconTrackingOrder,
  iconLanguage,
  iconHotline,
  handleClickTracking,
  languageSelected,
  logo,
}) => {
  const navigate = useNavigate();

  const { isMobile, isTablet } = useDeviceQueries();
  const [isShowWhenScroll, setIsShowWhenScroll] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [value, setValue] = useState('');
  const { t, i18n } = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);

  const staticPage = useAppSelector((state) => state.systems.staticPage);

  const slugSearchShopPage = useMemo(() => staticPage?.find(
    (page) => page.pageData.code === TEMPLATE_CODE_CONFIG.SEARCH_SHOP_PAGE
  )?.pageData?.slug, [staticPage]);

  useWindowScroll(() => {
    if (window.pageYOffset > 300) {
      setIsShowWhenScroll(true);
    } else {
      setIsShowWhenScroll(false);
    }
  });

  const handleSearch = useCallback(() => {
    if (value) {
      navigate(`${concatLangWithSlug(i18n.language, slugSearchShopPage)}?keyword=${value}`);
      setValue('');
    }
  }, [value, slugSearchShopPage, navigate, i18n.language]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      navigate(`${concatLangWithSlug(i18n.language, slugSearchShopPage)}?keyword=${e.currentTarget.value}`);
      setValue('');
    }
  }, [navigate, slugSearchShopPage, i18n.language]);

  return (
    <div className={mapModifiers('t-header')}>
      {/* Header Upper */}
      <div className="t-header_wrapUpper">
        <Container>
          <div className="t-header_upper">
            <div className="t-header_upper-right">
              <ul className="t-header_upper-list">
                <li className="d-flex align-items-center t-header_upper-hotline">
                  <Text modifiers={['black', '12x16']}>
                    Hotline:
                    {' '}
                    <Link useExternal href={`tel:${hotLine}`}>
                      {hotLine}
                    </Link>
                  </Text>
                </li>
                <li>
                  {/* <div className="u-mr-2 u-mr-lg-10">
                    <div className="t-header_upper-icon">
                      <img src={iconLanguage} alt="tracking" width={16} height={16} />
                    </div>
                  </div> */}
                  <Pulldown
                    options={languageOptions}
                    handleSelected={handleChangeLanguage}
                    selected={languageSelected || languageOptions?.[0]}
                  />
                </li>
                {/* <li>
                  <div className="u-mr-2 u-mr-lg-10">
                    <div className="t-header_upper-icon">
                      <img src={iconCurrency} alt="tracking" />
                    </div>
                  </div>
                  <Pulldown
                    options={coinOptions}
                    handleSelected={handleChangeCoin}
                    selected={coinOptions?.[0]}
                  />
                </li> */}
              </ul>
            </div>
          </div>
        </Container>
      </div>
      {/* Header Main */}
      <div className={mapModifiers('t-header_WrapMain', isShowWhenScroll && 'showWhenScroll')}>
        <Container>
          <div className="t-header_main">
            <div className="t-header_main-left">
              {
                logo?.data && (
                  <h1 className="t-header_main-left_logo">
                    <Link href={getHomeLangURL(i18n.language)}>
                      <Image size="contain" ratio="127x33" src={logo?.data?.path} alt={logo?.data?.alt} />
                    </Link>
                  </h1>
                )
              }
            </div>
            <div className="t-header_main-menu">
              <div className="t-header_main-right-search">
                <Input
                  placeholder={t('tracking.search') || ''}
                  variant="headerSearch"
                  onChange={(e) => setValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  value={value}
                  handleClickToSearch={handleSearch}
                />
              </div>
            </div>
            <div className="t-header_main-right">
              <ul className="t-header_main-right_wrapTool">
                <li className="t-header_main-right-menu">
                  {
                    !(isMobile || isTablet) && (
                      <Menu listMenus={listMenus} />
                    )
                  }
                </li>
                <li className="t-header_main-cart" onClick={handlePressCart}>
                  <div className="t-header_main-iconCart">
                    <Icon iconName="shoppingBagBlack" size="20" />
                    {
                      numberCart && (
                        <div className="t-header_main-numberCart">
                          <div className="t-header_main-numberCart-inside">
                            {numberCart}
                          </div>
                        </div>
                      )
                    }
                  </div>
                </li>
                {
                  (isMobile || isTablet) && (
                    <div className="t-header_main-mobile">
                      <div className="t-header_main-right-hamberger" onClick={() => setIsShowMenu((prev) => !prev)}>
                        <Icon iconName={isShowMenu ? 'cancel' : 'hamberger'} size="20" />
                      </div>
                      <div style={{ maxHeight: isShowMenu ? menuRef?.current?.clientHeight : 0 }} className={mapModifiers('t-header_main-mobile-menu', isShowMenu && 'show')}>
                        <div ref={menuRef}>
                          <MenuMobile listMenus={listMenus} />
                        </div>
                      </div>
                    </div>
                  )
                }
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

Header.defaultProps = {
};

export default Header;
