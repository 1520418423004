import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks';
import { getMenusAction } from 'store/menus';
import { getHeaderFootersAction, getStaticPageAsync, getSystemAsync } from 'store/systems';
import sdkChatFB from 'utils/sdkChatFB';

const useInitializeRender = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isLoadingSystem, setLoadingSystem] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language) {
      setLoadingSystem(true);
      dispatch(getHeaderFootersAction());
      dispatch(getMenusAction());
      dispatch(getStaticPageAsync());
      dispatch(getSystemAsync()).unwrap().then((res) => {
        if (res.others.favicon) {
          const favicon = document.getElementById('favicon') as HTMLLinkElement;
          favicon.href = res.others.favicon;
        }
      })
        .finally(() => setLoadingSystem(false));
    }
  }, [dispatch, i18n.language]);

  useEffect(() => {
    document.body.classList.remove('overflow-body');
    document.documentElement.classList.remove('overflow-body');
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    sdkChatFB('113717061743659');
  }, []);

  return {
    isLoadingSystem
  };
};

export default useInitializeRender;
