import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import HelmetContainer from 'common/helmet';
import Heading from 'components/atoms/Heading';
import PopupConfirm, { PopupConfirmProps } from 'components/organisms/PopupConfirm';
import CheckoutForm, { FormType } from 'components/templates/CheckoutForm';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { postCreateOrderService } from 'services/orders';
import { CreateOrderParams } from 'services/orders/types';
import { useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE_KEY } from 'utils/constant';
import { setCookie } from 'utils/functions';
import { concatLangWithSlug, getStaticSlug } from 'utils/language';

const CheckoutPageContainer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { confirmList: orderList } = useAppSelector((state) => state.cart);
  const exchangeRate = useAppSelector((state) => state.systems.system?.others.exchangeRate) || 0;
  const taxPercent = useAppSelector((state) => state.systems.system?.others.tax) || 0;
  const [modalConfirm, setModalConfirm] = useState<PopupConfirmProps>();
  const navigate = useNavigate();

  const listOrdered = getLocalStorage(LOCAL_STORAGE_KEY.LIST_ORDERED);

  const totalOrdered = () => orderList.reduce((
    total,
    order
  ) => total + order.productList.reduce((
    productTotal,
    product
  ) => productTotal + (product.total || 0), 0), 0);

  const { mutate: mutateCreateOrder, isLoading } = useMutation(postCreateOrderService, {
    onSuccess(res) {
      const listOrderedParsed = listOrdered && JSON.parse(listOrdered || '');
      if (Array.isArray(listOrderedParsed)) {
        setLocalStorage(
          LOCAL_STORAGE_KEY.LIST_ORDERED,
          JSON.stringify([...res.codes, ...listOrderedParsed])
        );
      } else {
        setLocalStorage(LOCAL_STORAGE_KEY.LIST_ORDERED, JSON.stringify(res.codes));
      }

      setCookie(LOCAL_STORAGE_KEY.LIST_ORDERED, JSON.stringify(res.codes), 0.5);
      setCookie(LOCAL_STORAGE_KEY.TOTAL_ORDERED, totalOrdered().toString(), 0.5);

      navigate(concatLangWithSlug(i18n.language, getStaticSlug('THANKS', i18n.language)));
    },
    onError() {
      setModalConfirm({
        isOpen: true,
        iconName: 'warning',
        title: <Heading type="h5" modifiers={['center', '600', 'sinopia', '34x48']}>{t('checkout.error')}</Heading>,
        desc: t('checkout.tryAgain').toString(),
        btnConfirm: {
          label: t('checkout.retry'),
          onClick: () => {
            setModalConfirm((prev) => ({ ...prev, isOpen: false }));
          }
        }
      });
    }
  });

  const handleSetLocalStorage = (data: FormType) => {
    setLocalStorage(LOCAL_STORAGE_KEY.CHECKOUT_INFO, JSON.stringify(data));

    const feeServiceCostTotal = orderList.reduce((totalFee, order) => {
      const feeService = order?.feeServiceCost || 0;
      return totalFee + feeService;
    }, 0);

    setCookie(LOCAL_STORAGE_KEY.SD_SERVICE_FEE_PRICE, feeServiceCostTotal.toString(), 0.5);

    const submitData: CreateOrderParams = {
      orders: orderList.map((order) => ({
        order: {
          tax: taxPercent,
          paymentMethod: data.pay,
          note: order.note,
          shopId: order.brandId,
          exchangeRate,
          serviceFee: order.feeServicePercent || 0,
          discount: order.discountPercent || 0
        },
        orderItems: order.productList.map((product) => ({
          name: product.name,
          price: product.priceVND,
          quantity: product.quantity,
          originalPrice: product.price,
          note: product.note,
          url: product.link,
        }))
      })),
      shippingAddress: {
        name: data.name,
        phone: data.phone,
        email: data.email,
        provinceId: data.city?.id,
        districtId: data.district?.id,
        wardId: data.ward?.id,
        address: data.street,
        deliveryTime: Number(data.deliveryTime)
      }
    };
    mutateCreateOrder(submitData);
  };
  return (
    <div className="p-checkoutPageContainer">
      <HelmetContainer />
      <CheckoutForm
        handleSubmit={(data) => handleSetLocalStorage(data)}
        loading={isLoading}
      />
      <PopupConfirm
        isCheckoutPage
        {...modalConfirm}
      />
    </div>
  );
};
export default CheckoutPageContainer;
