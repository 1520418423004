import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const phoneRegExp = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
export const remove = '';

const useSchemas = () => {
  const { t } = useTranslation();

  const schemaCheckoutForm = yup.object({
    name: yup.string().required(`${t('schema.required')}`),
    phone: yup.string().required(`${t('schema.required')}`).matches(phoneRegExp, `${t('schema.notMatchFormat')}`),
    email: yup.string().required(`${t('schema.required')}`).email(`${t('schema.emailInvalid')}`),
    city: yup.object().required(`${t('schema.required')}`),
    district: yup.object().required(`${t('schema.required')}`),
    ward: yup.object().required(`${t('schema.required')}`),
    street: yup.string().required(`${t('schema.required')}`),
  });

  const schemaContactForm = yup.object({
    name: yup.string().required(`${t('schema.required')}`),
    phone: yup.string().required(`${t('schema.required')}`).matches(phoneRegExp, `${t('schema.notMatchFormat')}`),
    email: yup.string().required(`${t('schema.required')}`).email(`${t('schema.emailInvalid')}`),
    content: yup.string().required(`${t('schema.required')}`),
  });

  return {
    schemaCheckoutForm,
    schemaContactForm
  };
};

export default useSchemas;
