import React from 'react';
import { Container } from 'react-bootstrap';

import Heading from 'components/atoms/Heading';
import CustomCollapse from 'components/organisms/Collapse';

interface FrequentlyAskedCardProps {
  title?: string,
  content?: string
}

export interface FrequentlyAskedQuestionsProps {
  title: string;
  dataCard: FrequentlyAskedCardProps[]
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({ title, dataCard }) => (
  <section className="t-frequentlyAskedQuestions">
    <Container>
      <div className="t-frequentlyAskedQuestions_content">
        <div className="t-frequentlyAskedQuestions_heading">
          <Heading modifiers={['600', '24x30', 'vividTangelo', 'fontSanFranciscoDisplay']}>
            {title}
          </Heading>
        </div>
        <div className="t-frequentlyAskedQuestions_list">
          {dataCard.map((item, idx) => (
            <div key={`t-frequentlyAskedQuestions_list_item-${idx.toString()}`} className="t-frequentlyAskedQuestions_list_item">
              <CustomCollapse {...item} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  </section>
);

export default FrequentlyAskedQuestions;
