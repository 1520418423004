import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import Icon, { IconName } from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

interface CollapseProps {
  children?: React.ReactNode;
  title: string;
  iconShow?: IconName;
  iconHide?: IconName;
  noIcon?: boolean;
}

const CustomCollapse: React.FC<CollapseProps> = ({
  children, title, iconHide = 'minus', iconShow = 'plus', noIcon
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="m-collapse">
      <div className="m-collapse_head" onClick={() => setIsOpen(!isOpen)}>
        <Text modifiers={['14x20', 'yankeesBlue', '500']}>
          {title}
        </Text>
        {
          !noIcon && (
            <div className="u-ml-5">
              <Icon iconName={isOpen ? iconHide : iconShow} size="14" />
            </div>
          )
        }
      </div>
      <Collapse in={isOpen}>
        <div className="m-collapse_content">
          {children}
        </div>
      </Collapse>
    </div>
  );
};

export default CustomCollapse;
