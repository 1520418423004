import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { OptionType } from 'components/molecules/Pulldown';
import { getDistrictService, getProvincesService, getWardDataService } from 'services/location';

interface Props {
  codeProvince?: string;
  codeDistrict?: string;
}

interface Response {
  wards: {
    options: OptionType[];
    loading?: boolean;
  };
  districts: {
    options: OptionType[];
    loading?: boolean;
  };
  provinces: {
    options: OptionType[];
    loading?: boolean;
  };
}

const useAddress = ({ codeDistrict, codeProvince }: Props): Response => {
  const [provinces, setProvinces] = useState<OptionType[]>([]);
  const [districts, setDistricts] = useState<OptionType[]>([]);
  const [wards, setWards] = useState<OptionType[]>([]);

  const { i18n } = useTranslation();

  const { isLoading: loadingProvince } = useQuery(
    ['get', 'province', i18n.language],
    () => getProvincesService({ limit: 200 }),
    {
      onSuccess: (res) => {
        setProvinces(res?.data?.map((item) => ({
          label: item?.provinceData?.name || '',
          value: item?.provinceData?.code,
          id: item?.provinceData?.id,
        })));
      }
    }
);

  const {
    mutate: mutateDistrict,
    isLoading: loadingDistrict
  } = useMutation(getDistrictService, {
    onSuccess: (res) => {
      setDistricts(res?.data?.map((item) => ({
        label: item?.provinceData?.name || '',
        value: item?.provinceData?.code,
        id: item?.provinceData?.id,
      })));
    }
  });

  const { mutate: mutateWard, isLoading: loadingWard } = useMutation(getWardDataService, {
    onSuccess: (res) => {
      setWards(res?.data.map((item) => ({
        label: item.wardData.name || '',
        id: item?.wardData?.id,
        value: item.wardData.code,
      })));
    }
  });

  useEffect(() => {
    if (codeProvince) {
      setDistricts([]);
      setWards([]);
      mutateDistrict(codeProvince);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeProvince, i18n.language]);

  useEffect(() => {
    if (codeDistrict) {
      setWards([]);
      mutateWard(codeDistrict);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDistrict, i18n.language]);

  return {
    wards: {
      options: wards,
      loading: loadingWard,
    },
    districts: {
      options: districts,
      loading: loadingDistrict,
    },
    provinces: {
      options: provinces,
      loading: loadingProvince,
    },
  };
};

export default useAddress;
