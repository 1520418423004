import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import FrameWrap from 'components/organisms/FrameWrap';
import useAddress from 'hooks/useAddress';
import { getLocalStorage } from 'services/common/storage';
import { LOCAL_STORAGE_KEY } from 'utils/constant';
import useSchemas from 'utils/schemas';

export type FormType = {
  name: string;
  phone: string;
  email: string;
  city: OptionType,
  district: OptionType | null,
  ward: OptionType | null,
  street: string
  note: string,
  pay: number,
  deliveryTime: string,
};
interface CheckoutFormProps {
  handleSubmit: (data: FormType) => void;
  loading?: boolean;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  handleSubmit, loading
}) => {
  const { t } = useTranslation();
  const { schemaCheckoutForm } = useSchemas();
  const method = useForm<FormType>({
    resolver: yupResolver(schemaCheckoutForm),
  });
  const checkoutInfoStorage = getLocalStorage(LOCAL_STORAGE_KEY.CHECKOUT_INFO);

  const codeProvince = method.watch('city');
  const codeDistrict = method.watch('district');

  const { provinces, districts, wards } = useAddress({
    codeProvince: codeProvince?.value?.toString(),
    codeDistrict: codeDistrict?.value?.toString(),
  });

  useEffect(() => {
    if (checkoutInfoStorage) {
      const data = JSON.parse(checkoutInfoStorage);
      method.reset(data);
    }
  }, [checkoutInfoStorage, method]);
  return (
    <div className="t-checkoutForm">
      <Container>
        <FormProvider {...method}>
          <form noValidate onSubmit={method.handleSubmit(handleSubmit)}>
            <FrameWrap
              headerNode={
                <div className="t-checkoutForm_header"><Text modifiers={['16x24', 'black', '700']} content={`${t('checkout.EnterDeliveryInformation')}`} /></div>
              }
            >
              <div className="t-checkoutForm_form">
                <div className="t-checkoutForm_wrapInput">
                  <div className="t-checkoutForm_wrapInput-label">
                    <span className="t-checkoutForm-required">*</span>
                    <Text modifiers={['black', '14x22']} content={`${t('checkout.name')}:`} />
                  </div>
                  <div className="t-checkoutForm_wrapInput-input">
                    <Controller
                      name="name"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          error={fieldState?.error?.message}
                          placeholder={t('checkout.name') || ''}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="t-checkoutForm_wrapInput">
                  <div className="t-checkoutForm_wrapInput-label">
                    <span className="t-checkoutForm-required">*</span>
                    <Text modifiers={['black', '14x22']} content={`${t('checkout.phone')}:`} />
                  </div>
                  <div className="t-checkoutForm_wrapInput-input">
                    <Controller
                      name="phone"
                      render={({ field, fieldState }) => (
                        <Input
                          type="tel"
                          {...field}
                          error={fieldState?.error?.message}
                          placeholder={`${t('checkout.phone')}`}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="t-checkoutForm_wrapInput">
                  <div className="t-checkoutForm_wrapInput-label">
                    <span className="t-checkoutForm-required">*</span>
                    <Text modifiers={['black', '14x22']} content="Email:" />
                  </div>
                  <div className="t-checkoutForm_wrapInput-input">
                    <Controller
                      name="email"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          error={fieldState?.error?.message}
                          placeholder="Email"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="t-checkoutForm_wrapPulldown">
                  <div className="t-checkoutForm_wrapPulldown-label">
                    <span className="t-checkoutForm-required">*</span>
                    <Text modifiers={['black', '14x22']} content={`${t('checkout.address')}:`} />
                  </div>
                  <div className="t-checkoutForm_wrapPulldown-content">
                    <div className="t-checkoutForm_wrapPulldown-item">
                      <Controller
                        name="city"
                        render={({
                          field,
                          fieldState,
                        }) => (
                          <Pulldown
                            placeholder={`${t('checkout.province')}`}
                            isSearch
                            options={provinces.options}
                            value={field.value}
                            handleSelect={(val) => {
                              field.onChange(val);
                              method.resetField('district', { defaultValue: null });
                              method.resetField('ward', { defaultValue: null });
                            }}
                            error={fieldState?.error?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="t-checkoutForm_wrapPulldown-item">
                      <Controller
                        name="district"
                        render={({
                          field,
                          fieldState,
                        }) => (
                          <Pulldown
                            placeholder={`${t('checkout.district')}`}
                            isSearch
                            options={districts.options}
                            value={field.value}
                            handleSelect={(val) => {
                              field.onChange(val);
                              method.resetField('ward', { defaultValue: null });
                            }}
                            error={fieldState?.error?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="t-checkoutForm_wrapPulldown-item">
                      <Controller
                        name="ward"
                        render={({
                          field,
                          fieldState,
                        }) => (
                          <Pulldown
                            placeholder={`${t('checkout.ward')}`}
                            isSearch
                            options={wards.options}
                            value={field.value}
                            handleSelect={field.onChange}
                            error={fieldState?.error?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="t-checkoutForm_wrapPulldown-item">
                      <Controller
                        name="street"
                        render={({ field, fieldState }) => (
                          <Input
                            {...field}
                            error={fieldState?.error?.message}
                            placeholder={`${t('checkout.street')}`}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="t-checkoutForm_wrapInput">
                  <div className="t-checkoutForm_wrapInput-label">
                    <Text modifiers={['black', '14x22']} content={`${t('checkout.note')}:`} />
                  </div>
                  <div className="t-checkoutForm_wrapInput-input">
                    <Controller
                      name="note"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          error={fieldState?.error?.message}
                          placeholder={`${t('checkout.note')}`}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="t-checkoutForm_wrapInput">
                  <div className="t-checkoutForm_wrapInput-label">
                    <span className="t-checkoutForm-required">*</span>
                    <Text modifiers={['black', '14x22']}>{t('checkout.timeShip')}</Text>
                  </div>
                  <div>
                    <Controller
                      name="deliveryTime"
                      defaultValue="1"
                      render={({ field }) => (
                        <div className="t-checkoutForm_timeShip">
                          <Radio
                            label={`${t('checkout.timeShipDefault')}`}
                            id="default"
                            value="1"
                            checked={field.value === '1'}
                            onChange={(e) => field.onChange(e.currentTarget.value)}
                          />
                          <div className="t-checkoutForm_timeShip_radio">
                            <Radio
                              label={`${t('checkout.overtime')}`}
                              id="overtime"
                              value="2"
                              checked={field.value === '2'}
                              onChange={(e) => field.onChange(e.currentTarget.value)}
                            />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </FrameWrap>
            <div className="t-checkoutForm_bottom">
              <FrameWrap
                headerNode={<div className="t-checkoutForm_header"><Text modifiers={['16x24', 'black', '700']} content={`${t('checkout.paymentMethod')}`} /></div>}
              >
                <Controller
                  name="pay"
                  defaultValue="2"
                  render={({ field }) => (
                    <div className="t-checkoutForm_pay">
                      <Radio
                        label="MoMo"
                        id="MoMo"
                        value="2"
                        checked={field.value === '2'}
                        onChange={(e) => field.onChange(e.currentTarget.value)}
                      />
                      <div className="t-checkoutForm_payment">
                        <Radio
                          label={`${t('checkout.transfer')}`}
                          id="cast"
                          value="1"
                          checked={field.value === '1'}
                          onChange={(e) => field.onChange(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  )}
                />

              </FrameWrap>
            </div>
            <div className="t-checkoutForm_button">
              <Button loading={loading} type="submit" modifier={['xs']}>
                <Text modifiers={['14x22', 'center', '700', 'white']}>{t('checkout.continue')}</Text>
              </Button>
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

CheckoutForm.defaultProps = {
};

export default CheckoutForm;
