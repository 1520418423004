import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import HelmetContainer from 'common/helmet';
import Loading from 'components/atoms/Loading';
import FrequentlyAskedQuestionsTemplate, {
  FrequentlyAskedQuestionsProps
} from 'components/templates/FrequentlyAskedQuestions';
import { getFaqCategoryBySlugService } from 'services/faqs';

const FrequentlyAskedQuestionsContainer: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { i18n } = useTranslation();

  const { data: faqCategoryDetail, isLoading } = useQuery(
    ['getFrequentlyAskedQuestions', slug],
    () => {
      if (slug) return getFaqCategoryBySlugService(slug);
      return undefined;
    },
    {
      enabled: !!slug
    }
  );

  const dataCard = useMemo((): FrequentlyAskedQuestionsProps => {
    if (faqCategoryDetail) {
      return {
        title: faqCategoryDetail.translations?.[i18n.language]?.name || '',
        dataCard: faqCategoryDetail.faq.map((item) => ({
          title: item.question,
          content: item.answer
        })),
      };
    }
    return {
      title: '',
      dataCard: []
    };
  }, [faqCategoryDetail, i18n.language]);

  if (isLoading) return <Loading variant="fullScreen" />;

  return (
    <>
      <HelmetContainer />
      <FrequentlyAskedQuestionsTemplate
        {...dataCard}
      />
    </>
  );
};

export default FrequentlyAskedQuestionsContainer;
