import React, { InputHTMLAttributes } from 'react';

import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

type Variant = 'secondary';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  variant?: Variant;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label, id, variant, ...props
}) => (
  <div className={mapModifiers('a-checkbox', variant, props.checked && 'secondary-active')}>
    <label htmlFor={id} className="a-checkbox_wrapper">
      <input
        id={id}
        type="checkbox"
        {...props}
      />
      {label && (
        <Text modifiers={(props.checked && ['vividTangelo']) || ['black']}>{label}</Text>
      )}
    </label>
  </div>
);

Checkbox.defaultProps = {
  children: undefined,
};

export default Checkbox;
