import { TFunction } from 'i18next';

export const LOCAL_STORAGE_KEY = {
  SD_ORDER: 'SD_ORDER',
  SD_ORDER_CONFIRM: 'SD_ORDER_CONFIRM',
  CHECKOUT_INFO: 'CHECKOUT_INFO',
  LIST_ORDERED: 'LIST_ORDERED',
  TOTAL_ORDERED: 'TOTAL_ORDERED',
  LANGUAGE: 'SAN_DEAL_LANGUAGE',
  TOTAL_ORDER_DISCOUNT: 'TOTAL_ORDER_DISCOUNT',
  SD_SERVICE_FEE_PRICE: 'SD_SERVICE_FEE_PRICE',
};

export type ConstantRoutesCodes = keyof typeof CONSTANT_ROUTES;
export type ConstantRoutesType = typeof CONSTANT_ROUTES;

export const CONSTANT_ROUTES = {
  CHECKOUT: 'thong-tin-nhan-hang',
  CART: 'gio-hang',
  REVIEW_ORDER: 'kiem-tra-don-hang',
  SHOP: 'cua-hang',
  THANKS: 'cam-on',
  FREQUENTLY_ASKED_QUESTION: 'chi-tiet-nhung-cau-hoi-thuong-gap'
};

export const CONSTANT_ROUTES_EN: ConstantRoutesType = {
  CHECKOUT: 'checkout',
  CART: 'cart',
  REVIEW_ORDER: 'review-order',
  SHOP: 'shop',
  THANKS: 'thanks',
  FREQUENTLY_ASKED_QUESTION: 'frequently-asked-questions-details'
};

export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const CONSTANT_LANG: Record<LanguageType, keyof LocalesResponse> = {
  VI: 'vi',
  EN: 'en',
};

export const CONSTANT_LANGUAGE_LIST = ['vi', 'en'];

export const ORDER_STATIC_STATUS = (t: TFunction<'translation', undefined>) => [
  {
    status: 1,
    title: t('tracking.waitForConfirmation'),
    desc: t('tracking.waitConfirmDesc'),
    active: true
  },
  {
    status: 2,
    title: t('tracking.ordered'),
    desc: t('tracking.orderedDesc'),
    active: true
  },
  {
    status: 3,
    title: t('tracking.storedInStock'),
    desc: t('tracking.storedDesc'),
    active: true
  },
  {
    status: 4,
    title: t('tracking.waitingForDelivery'),
    desc: t('tracking.deliveryDesc'),
    active: true
  },
  {
    status: 5,
    title: t('tracking.delivering'),
    desc: t('tracking.deliveringDesc'),
    active: true
  },
  {
    status: 6,
    title: t('tracking.delivered'),
    desc: t('tracking.deliveredDesc'),
    active: true
  },
  {
    status: 8,
    title: t('cart.cancel'),
    desc: t('tracking.cancelDesc'),
    active: false
  }
];
