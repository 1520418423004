import React from 'react';

interface FrameWrapProps {
  children?: React.ReactNode;
  headerNode?: React.ReactNode;
}

const FrameWrap: React.FC<FrameWrapProps> = ({ headerNode, children }) => (
  <div className="o-frameWrap">
    <div className="o-frameWrap_header">
      {headerNode}
    </div>
    <div className="o-frameWrap_body">
      {children}
    </div>
  </div>
);

FrameWrap.defaultProps = {
  children: undefined,
};

export default FrameWrap;
