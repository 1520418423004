import { FaqCategoryDataTypes, FaqCategoryDetailDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getFaqCategoryService = async (): Promise<FaqCategoryDataTypes[]> => {
  const res = await axiosInstance.get('faq-categories/get-list');
  return res.data?.data;
};

export const getFaqCategoryBySlugService = async (slug: string)
  : Promise<FaqCategoryDetailDataTypes> => {
  const res = await axiosInstance.get(`faq-categories/${slug}`);
  return res.data?.data;
};
