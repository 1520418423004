const sdkChatFB = (idChatFB: string) => {
  try {
    const newWindow = window as any;
    const chatbox = document.getElementById('fb-customer-chat');
    if (chatbox && idChatFB) {
      chatbox.setAttribute('page_id', idChatFB); // "113717061743659"
      chatbox.setAttribute('attribution', 'biz_inbox');
    }

    newWindow.fbAsyncInit = function () {
      console.log('FB: ', newWindow?.FB);

      newWindow?.FB.init({
        xfbml: true,
        version: 'v17.0'
      });
    };

    (function (d, s, id) {
      const js: any = d.createElement(s);
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js.id = id;
      js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  } catch (e) {
    // throw error sdk FB
  }
};

export default sdkChatFB;
