import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import Text from 'components/atoms/Text';

interface CollapseProps {
  title?: string;
  content?: string;
}

const CustomCollapse: React.FC<CollapseProps> = ({
  title,
  content
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const headingColor = isOpen ? 'vividTangelo' : 'black';

  return (
    <div className="o-collapse">
      <div className="o-collapse_head" onClick={() => setIsOpen(!isOpen)}>
        <Text modifiers={['16x24', '500', headingColor, 'fontSanFranciscoDisplay']}>
          {title}
        </Text>
        <div className="o-collapse_icon">
          <div className="o-collapse_icon_line" />
          <div className={`o-collapse_icon_line ${!isOpen && 'o-collapse_icon_line_rotate'}`} />
        </div>
      </div>
      <Collapse in={isOpen}>
        <div className="o-collapse_content">
          <Text type="div" modifiers={['16x24', 'black', '400', 'fontSanFranciscoDisplay']} content={content} />
        </div>
      </Collapse>
    </div>
  );
};

export default CustomCollapse;
