import React, { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TEMPLATE_CODE_CONFIG } from 'common/Navigations/constants';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import { clearWhenCheckout } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { concatLangWithSlug } from 'utils/language';

const Thanks: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const taxPercent = useAppSelector((state) => state.systems.system?.others.tax) || 0;
  const staticPages = useAppSelector((state) => state.systems.staticPage);

  const slugPaymentPage = useMemo(() => staticPages?.find(
    (page) => page.pageData.code === TEMPLATE_CODE_CONFIG.PAYMENT_PAGE
  )?.pageData?.slug, [staticPages]) || '';

  const goToPayment = () => {
    dispatch(clearWhenCheckout({ tax: taxPercent }));
    navigate(concatLangWithSlug(i18n.language, slugPaymentPage), { replace: true });
  };

  return (
    <div className="p-thanks">
      <Container>
        <Text modifiers={['400', '24x29', 'black', 'fontInter', 'center']}>{t('checkout.orderSuccess')}</Text>
        <div className="p-thanks_heading">
          <Heading type="h2" modifiers={['600', 'vividTangelo', 'center', 'fontInter', '65x90']}>CÁM ƠN!</Heading>
        </div>
        <div className="p-thanks_button">
          <Button modifier={['xs']} onClick={goToPayment}>
            <Icon iconName="addCart" />
            <Text modifiers={['14x22', '700', 'fontSanFranciscoDisplay']}>{t('checkout.payNow')}</Text>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Thanks;
