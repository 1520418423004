import React, { InputHTMLAttributes } from 'react';

import Text from 'components/atoms/Text';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Radio: React.FC<RadioProps> = ({ label, id, ...props }) => (
  <div className="a-radio">
    <label htmlFor={id} className="a-radio_wrapper">
      <input
        id={id}
        type="radio"
        {...props}
      />
      {label && (
        <Text modifiers={['dimGray', '14x22']}>{label}</Text>
      )}
    </label>
  </div>
);

Radio.defaultProps = {
  children: undefined,
};

export default Radio;
