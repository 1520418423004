import React from 'react';

import Icon from '../Icon';

import mapModifiers from 'utils/functions';

type Variant = 'search' | 'formContact' | 'headerSearch' | 'brandSearch';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  variant?: Variant;
  textSearch?: string;
  handleDecrease?: () => void;
  handleIncrease?: () => void;
  handleClickToSearch?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((
  {
    error,
    type,
    variant,
    textSearch,
    handleDecrease,
    handleIncrease,
    handleClickToSearch,
    ...props
  },
  ref,
) => (
  <>
    <div className={mapModifiers('a-input', type === 'number' ? 'amount' : '', error && 'error', variant)}>
      <input
        {...props}
        type={type}
        className="a-input_input"
        ref={ref}
      />
      {
        type === 'number' && (
          <div className="a-input_actions">
            <div className="a-input_actions_item increase" onClick={handleIncrease} />
            <div className="a-input_actions_item decrease" onClick={handleDecrease} />
          </div>
        )
      }
      {
        (variant === 'brandSearch' || variant === 'headerSearch') && (
          <div role="button" id="buttonSearchdiv" aria-labelledby="buttonSearchdiv" className="a-input_btnSearch" onClick={handleClickToSearch}>
            <Icon iconName={variant === 'brandSearch' ? 'whiteSearch' : 'blackSearch'} size="19x21" />
          </div>
        )
      }
    </div>
    {error && (
      <span className="a-input_error">{error}</span>
    )}
  </>
));

export default Input;
