import React, { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import HelmetContainer from 'common/helmet';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import ShoppingCartTable from 'components/organisms/ShoppingCartTable';
import { setLocalStorage } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE_KEY } from 'utils/constant';
import { renderValue } from 'utils/functions';
import { getURLCode } from 'utils/language';

const ReviewOrderContainer: React.FC = () => {
  const { confirmList } = useAppSelector((state) => state.cart);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const totalProduct = useMemo(
    () => confirmList.reduce((
      prev,
      curr
    ) => prev + curr.productList.reduce((
      prevProduct,
      currProduct
    ) => prevProduct + currProduct.quantity, 0), 0),
    [confirmList]
  );

  useEffect(() => {
    const totalDiscount = confirmList
      .reduce((prev, cur) => prev + (cur.totalDiscountCost || 0), 0);
    if (totalDiscount !== 0) {
      setLocalStorage(
        LOCAL_STORAGE_KEY.TOTAL_ORDER_DISCOUNT,
        JSON.stringify(totalDiscount)
      );
    }
  }, [confirmList]);

  return (
    <Container>
      <HelmetContainer />
      {
        confirmList && confirmList.length ? (
          <>
            {
              confirmList.map((item, key) => (
                <div key={`item${key.toString()}`} className="p-reviewOrder_item">
                  <ShoppingCartTable {...item} isConfirm />
                </div>
              ))
            }
            <div className="p-reviewOrder_total u-mt-40">
              <Text modifiers={['18x23', 'black']}>
                {t('reviewOrder.grossProduct')}
                :
              </Text>
              <div className="p-reviewOrder_total-price">
                <Text modifiers={['18x23', 'black', '700']}>{totalProduct}</Text>
              </div>
            </div>
            <div className="p-reviewOrder_total u-mt-10">
              <Text modifiers={['18x23', 'black']}>
                {t('payment.totalMoney')}
                :
              </Text>
              <div className="p-reviewOrder_total-price">
                <Text modifiers={['18x23', 'black', '700']}>{renderValue(confirmList.reduce((prev, curr) => prev + Number(curr.finalTotal), 0), true)}</Text>
              </div>
            </div>
            <div className="p-reviewOrder_button" onClick={() => navigate(getURLCode(i18n.language, 'CHECKOUT'))}>
              <Button>{t('reviewOrder.pay')}</Button>
            </div>
          </>
        ) : (
          <div className="d-flex flex-column align-items-center u-pt-30">
            <Icon iconName="cartBlack" size="55" />
            <div className="u-mt-20 u-mb-20">
              <Heading type="h5" modifiers={['center', '34x48']}>{t('reviewOrder.chooseOrder')}</Heading>
            </div>
            <div>
              <Link href={getURLCode(i18n.language, 'CART')}>
                <Button>{t('header.cart')}</Button>
              </Link>
            </div>
          </div>
        )
      }
    </Container>
  );
};
export default ReviewOrderContainer;
