import React from 'react';

import { TemplateCode } from './constants';

const RenderPage: React.FC<
  Record<'data', BasePageDataTypes<any> | null | undefined>
> = ({ data }) => {
  if (!data) return null;

  const Component = TemplateCode?.find(
    (t) => t.templateCode === data?.pageData?.templateCode
  )?.component;

  if (Component) {
    return React.createElement<BasePageDataTypes<any>>(Component, data);
  }

  return null; // TODO: Implement Error Page
};

export default RenderPage;
