import React from 'react';

import FrequentlyAskedQuestionsContainer from 'containers/FrequentlyAskedQuestions';

const FrequentlyAskedQuestions: React.FC = () => (
  <div className="p-frequentlyAskedQuestions">
    <FrequentlyAskedQuestionsContainer />
  </div>
);

export default FrequentlyAskedQuestions;
