import React, { useMemo } from 'react';

import HomeNavigation from 'common/Navigations/homeNavigation';
import PageNavigation from 'common/Navigations/pageNavigation';
import Cart from 'pages/Cart';
import CheckoutPage from 'pages/CheckoutPage';
import FrequentlyAskedQuestions from 'pages/FrequentlyAskedQuestions';
import ReviewOrder from 'pages/ReviewOrder';
import Thanks from 'pages/Thanks';
import { useAppSelector } from 'store/hooks';
import {
  convertHomeRoute,
  convertRoute,
  convertStaticRoute,
  getActiveLanguages
} from 'utils/language';

const useRoutesList = () => {
  const locales = useAppSelector((state) => state.systems.system?.locales);

  const activeLangList = useMemo(
    () => getActiveLanguages(locales),
    [locales],
  );

  const routes = useMemo(
    () => ({
      home: {
        path: convertHomeRoute(activeLangList),
        element: <HomeNavigation />,
      },
      pages: {
        path: convertRoute(activeLangList, ':slug'),
        element: <PageNavigation />,
      },
      cartPage: {
        path: convertStaticRoute('CART', activeLangList, true),
        element: <Cart />,
      },
      checkoutPage: {
        path: convertStaticRoute('CHECKOUT', activeLangList, true),
        element: <CheckoutPage />,
      },
      reviewOrderPage: {
        path: convertStaticRoute('REVIEW_ORDER', activeLangList, true),
        element: <ReviewOrder />,
      },
      thanks: {
        path: convertStaticRoute('THANKS', activeLangList, true),
        element: <Thanks />,
      },
      frequentAskedQuestions: {
        path: convertStaticRoute('FREQUENTLY_ASKED_QUESTION', activeLangList),
        element: <FrequentlyAskedQuestions />,
      },
    }),

    [activeLangList],
  );

  return { activeLangList, routes };
};

export default useRoutesList;
