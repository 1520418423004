import React from 'react';

import CheckoutPageContainer from 'containers/CheckoutPage';

const CheckoutPage: React.FC = () => (
  <div className="p-checkoutPage">
    <CheckoutPageContainer />
  </div>
);

export default CheckoutPage;
