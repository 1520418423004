import { Params, ProvincesDataTypes, WardDataDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getProvincesService = async (params?: Params):
  Promise<APIPagination<ProvincesDataTypes[]>> => {
  const res = await axiosInstance.get('/provinces', { params });
  return res.data;
};

export const getDistrictService = async (code?: string, params?: Params):
  Promise<APIPagination<ProvincesDataTypes[]>> => {
  const res = await axiosInstance.get(`/districts/${code}`, { params });
  return res.data;
};

export const getWardDataService = async (code?: string, params?: Params):
  Promise<APIPagination<WardDataDataTypes[]>> => {
  const res = await axiosInstance.get(`/wards/${code}`, { params });
  return res.data;
};

export const Placeholder = '';
