import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  error: 'error',
  star: 'star',
  starActive: 'starActive',
  home: 'home',
  delivery: 'delivery',
  refund: 'refund',
  headphone: 'headphone',
  heart: 'heart',
  swap: 'swap',
  eye: 'eye',
  addCart: 'addCart',
  discount: 'discount',
  shoppingBag: 'shoppingBag',
  shoppingBagBlack: 'shoppingBagBlack',
  worldWide: 'worldWide',
  coin: 'coin',
  arrowDownWhite: 'arrowDownWhite',
  checkWhite: 'checkWhite',
  arrowDown: 'arrowDown',
  cart: 'cart',
  hamberger: 'hamberger',
  cancel: 'cancel',
  plus: 'plus',
  minus: 'minus',
  arrowDownGray: 'arrowDownGray',
  recycleBin: 'recycleBin',
  close: 'close',
  loading: 'loading',
  arrowUp: 'arrowUp',
  warning: 'warning',
  success: 'success',
  cartBlack: 'cartBlack',
  prevArrow: 'prevArrow',
  nextArrow: 'nextArrow',
  whiteSearch: 'whiteSearch',
  whiteCart: 'whiteCart',
  blackSearch: 'blackSearch',
  tracking: 'tracking',
  guide: 'guide',
};

export type IconName = keyof typeof iconList;

export type IconSize = '12' | '14' | '14x18' | '15' | '16' | '18' | '20' | '21x27' | '24' | '35' | '55' | '200' | '19x21';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}
const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />);

Icon.defaultProps = {
  size: '14',
};

export default Icon;
