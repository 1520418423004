import React, { useEffect, useRef, useState } from 'react';

import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import mapModifiers, { removeAccents } from 'utils/functions';

export type OptionType = {
  label: string;
  value?: number | string;
  id?: number;
};

interface PulldownProps {
  placeholder?: string;
  value?: OptionType;
  options: OptionType[];
  disabled?: boolean;
  handleSelect?: (option: OptionType) => void;
  error?: string;
  isSearch?: boolean;
  name?: string;
}

const Pulldown: React.FC<PulldownProps> = ({
  placeholder,
  value,
  options,
  disabled,
  error,
  handleSelect,
  isSearch,
  name,
}) => {
  const [txtSearch, setTxtSearch] = useState('');
  const pulldownRef = useRef<HTMLDivElement>(null);
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  useClickOutside(pulldownRef, () => {
    if (isOpen) setIsOpen(false);
  });
  useDebounce(
    () => {
      if (options.length > 0) {
        if (txtSearch) {
          setOptionData(
            options.filter(
              (option) => removeAccents(option?.label.toLowerCase())
                .includes(removeAccents(txtSearch.toLowerCase())),
            ),
          );
        } else {
          setOptionData(options);
        }
      }
    },
    1000,
    [txtSearch],
  );
  useEffect(() => {
    setOptionData(options);
  }, [options]);

  useEffect(() => {
    if (isSearch && value) {
      setTxtSearch(value?.label);
    }
  }, [isSearch, value]);

  return (
    <div
      className={`${mapModifiers('m-pulldown', disabled && 'disabled', error && 'error')}`}
      ref={pulldownRef}
    >
      <div className="m-pulldown_header" onClick={toggling}>
        {
          !isSearch && (
            <div
              className={`m-pulldown_header_content${value ? '' : ' m-pulldown_placeholder'
                }`}
            >
              <span>{(txtSearch || value) ? value?.label : placeholder}</span>
            </div>
          )
        }
        {isSearch && !disabled && (
          <input
            name={name}
            className="m-pulldown_search"
            value={txtSearch}
            onChange={(e) => setTxtSearch(e.currentTarget.value)}
            placeholder={placeholder}
          />
        )}
        <div className="m-pulldown_fn">
          <span className={isOpen ? 'm-pulldown_arrow opened' : 'm-pulldown_arrow'} />
        </div>
      </div>
      {isOpen && (
        <div className="m-pulldown_wrapper">
          <ul className="m-pulldown_list">
            {optionData.length ? (
              optionData.map((option, index) => (
                <li
                  key={`option-${index.toString()}`}
                  className={mapModifiers(
                    'm-pulldown_item',
                    value?.label === option.label && 'active',
                  )}
                  onClick={() => {
                    if (handleSelect) {
                      handleSelect(option);
                      setIsOpen(false);
                      setTxtSearch('');
                    }
                  }}
                >
                  <span>{option.label}</span>
                </li>
              ))
            ) : (
              <li className="m-pulldown_item none">No Option</li>
            )}
          </ul>
        </div>
      )}
      {error && (
        <div className="m-pulldown_error">
          {error}
        </div>
      )}
    </div>
  );
};

export default Pulldown;
