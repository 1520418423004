import {
  BrandListTypes,
  GeneralDataTypes,
  HeaderFooterDataTypes,
  PageStaticTypes
} from './types';

import axiosInstance from 'services/common/instance';

export const getHeaderFooterService = async (): Promise<HeaderFooterDataTypes> => {
  const res = await axiosInstance.get('systems/header-footer');
  return res.data?.data;
};

export const getGeneralService = async (): Promise<GeneralDataTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data?.data;
};

export const getPageStaticService = async (): Promise<PageStaticTypes[]> => {
  const res = await axiosInstance.get('pages/static/all');
  return res.data?.data;
};

export const getBlockService = async (endpoint: string, params: QueriesParamsType)
: Promise<BrandListTypes[]> => {
  const res = await axiosInstance.get(endpoint.replace('/api/v1/', ''), { params });
  return res.data?.data;
};
