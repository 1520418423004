import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import RenderPage from '.';

import Loading from 'components/atoms/Loading';
import { getStaticHomeService } from 'services/navigations';
import { useAppDispatch } from 'store/hooks';
import { setPageTranslation } from 'store/systems';

const HomeNavigation: React.FC = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading, isError } = useQuery(
    ['getDataHomePage'],
    () => getStaticHomeService<any>(),
  );

  useEffect(() => {
    if (data) {
      dispatch(setPageTranslation(data.translations));
    }
  }, [data, dispatch]);

  if (isLoading) {
    return <Loading variant="fullScreen" />;
  }

  if (isError) return null; // TODO: Implement Error

  return (
    <RenderPage data={data} />
  );
};

export default HomeNavigation;
