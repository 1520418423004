import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import cartReducer from './cart';
import exampleReducer from './example';
import menusReducer from './menus';
import shopsReducer from './shops';
import systemsReducer from './systems';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    example: exampleReducer,
    systems: systemsReducer,
    menus: menusReducer,
    cart: cartReducer,
    shops: shopsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
