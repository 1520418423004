import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from 'i18n';
import { setLocalStorage } from 'services/common/storage';
import { LanguageKey } from 'services/systems/types';
import { useAppSelector } from 'store/hooks';
import { CONSTANT_LANG, LOCAL_STORAGE_KEY } from 'utils/constant';
import {
  checkActiveLang, langLabel,
} from 'utils/language';

const useLanguage = () => {
  const navigate = useNavigate();

  const { system: dataSystem, pageTranslation } = useAppSelector((state) => state.systems);
  //* List languages
  const languageData = useMemo(() => {
    if (dataSystem?.locales) {
      return (Object.keys(dataSystem.locales) as Array<LanguageKey>).map(
        (ele) => ({ label: langLabel(ele), value: langLabel(ele) }),
      );
    }
    return [];
  }, [dataSystem]);

  const [languageSelected, setLanguageSelected] = useState<string>(
    i18n.language,
  );

  const handleLogicChangeLang = async (lang: LanguageType) => {
    if (pageTranslation) {
      const transData = pageTranslation[CONSTANT_LANG[lang]];
      if (transData) {
        navigate(`${transData?.slug !== '/' ? `/${transData?.slug || ''}` : ''}`);
      }
    }
  };

  const handleChangeLanguage = (lang: LanguageType) => {
    if (checkActiveLang(CONSTANT_LANG[lang], dataSystem?.locales)) {
      setLanguageSelected(CONSTANT_LANG[lang]);
      i18n.changeLanguage(CONSTANT_LANG[lang], () => {
        handleLogicChangeLang(lang);
      });
      setLocalStorage(LOCAL_STORAGE_KEY.LANGUAGE, CONSTANT_LANG[lang]);
    }
  };

  // useEffect(() => {
  //   if (dataSystem?.locales
  //     && !checkActiveLang(i18n.language as keyof LocalesResponse, dataSystem?.locales)) {
  //     setLanguageSelected(findLanguageDefault(dataSystem?.locales));
  //     i18n.changeLanguage(findLanguageDefault(dataSystem?.locales), () => {
  //       navigate(`${getLangURL(findLanguageDefault(dataSystem?.locales))}`);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataSystem]);

  return ({
    languageData,
    languageSelected: { label: langLabel(languageSelected), value: langLabel(languageSelected) },
    handleChangeLanguage,
  });
};

export default useLanguage;
