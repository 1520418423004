import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { TEMPLATE_CODE_CONFIG } from 'common/Navigations/constants';
import Icon from 'components/atoms/Icon';
import Footer from 'components/templates/Footer';
import Header from 'components/templates/Header';
import useLanguage from 'hooks/useLanguage';
import useWindowScroll from 'hooks/useWindowScroll';
import { initCart, initConfirmCart } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { concatLangWithSlug, getURLCode } from 'utils/language';
import groupMenus from 'utils/menus';

interface MainLayoutProps {
}

// const coinOptions = [
//   {
//     label: 'VND',
//     value: '1',
//   },
//   {
//     label: 'USD',
//     value: '2',
//   }
// ];

const MainLayout: React.FC<MainLayoutProps> = () => {
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();

  const navigator = useNavigate();
  const staticPages = useAppSelector((state) => state.systems.staticPage);

  const { footer, header } = useAppSelector((state) => state.systems);
  const { list } = useAppSelector((state) => state.menus);
  const [isShow, setIsShow] = useState(false);
  const { list: listOrder } = useAppSelector((state) => state.cart);
  const menuHeader = useMemo(() => {
    const headerMenu = list.find(
      (item) => item.code === header?.blocks?.header?.listMenus?.data
    )?.items;
    if (headerMenu && headerMenu.length > 0) {
      return groupMenus(headerMenu) || [];
    }
    return [];
  }, [header, list]);

  const menuFooter = useMemo(() => {
    const footerMenu = list.find((item) => item.code === footer?.blocks?.footer?.menu.data)?.items;
    if (footerMenu && footerMenu.length > 0) {
      return groupMenus(footerMenu) || [];
    }
    return [];
  }, [footer, list]);

  const imgListFooter = useMemo(() => footer?.blocks
    ?.footer?.imageList?.data?.map(
      (item) => item?.method?.data?.path || ''
    ), [footer]);

  const onScrollTop = useCallback(() => window.scrollTo({
    top: 0,
    behavior: 'smooth'
  }), []);

  useWindowScroll(() => {
    const isShowScrollTop = document.documentElement.scrollTop > 300;
    setIsShow(isShowScrollTop);
  });

  useEffect(() => {
    dispatch(initCart());
    dispatch(initConfirmCart());
  }, [dispatch, i18n.language]);

  const slugTrackingPage = useMemo(() => staticPages?.find(
    (page) => page.pageData.code === TEMPLATE_CODE_CONFIG.TRACKING_PAGE
  )?.pageData?.slug, [staticPages]);

  const { handleChangeLanguage, languageSelected, languageData } = useLanguage();

  return (
    <>
      <Header
        languageSelected={languageSelected}
        listMenus={menuHeader}
        // coinOptions={coinOptions}
        languageOptions={languageData}
        hotLine={header?.blocks?.header?.hotLine.data}
        textTrackingOrder={header?.blocks?.header?.textTrackingOrder?.data}
        // iconCurrency={header?.blocks?.header?.iconCurrency?.data?.path}
        iconLanguage={header?.blocks?.header?.iconLanguage?.data?.path}
        iconTrackingOrder={header?.blocks?.header?.iconTrackingOrder?.data?.path}
        iconHotline={header?.blocks?.header?.iconHotline?.data?.path}
        numberCart={listOrder.length || undefined}
        handlePressCart={() => navigator(getURLCode(i18n.language, 'CART'))}
        handleClickTracking={() => slugTrackingPage
          && navigator(concatLangWithSlug(i18n.language, slugTrackingPage))}
        handleChangeLanguage={(val) => handleChangeLanguage(val.value as LanguageType)}
        logo={header?.blocks.header.logo}
      />
      <main className="t-mainLayout">
        <Outlet />
      </main>
      <Footer
        dataFooter={menuFooter}
        imageList={imgListFooter || []}
        logo={footer?.blocks?.footer.logo}
        infoCompany={{
          nameCompany: footer?.blocks?.footer?.nameCompany,
          address: footer?.blocks?.footer?.address,
          licenseNo: footer?.blocks?.footer?.licenseNo,
        }}
      />
      <button className={`t-mainLayout_scrollUpBtn${isShow ? ' show' : ''}`} type="button" onClick={onScrollTop}>
        <Icon iconName="arrowUp" size="24" />
      </button>
    </>
  );
};

export default MainLayout;
