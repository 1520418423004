import axiosInstance from 'services/common/instance';

export const getStaticHomeService = async <T>(): Promise<BasePageDataTypes<T>> => {
  const res = await axiosInstance.get('pages/static/home-page');
  return res.data.data;
};

export const getPageService = async <T>(slug: string): Promise<BasePageDataTypes<T>> => {
  const res = await axiosInstance.get(`pages/${slug}`);
  return res.data.data;
};

export default undefined;
