import React from 'react';

import mapModifiers from 'utils/functions';

export type Sizes =
  | '18x23'
  | '24x30'
  | '34x48'
  | '36x51'
  | '46x64'
  | '65x90'
  | '60x90'
  | '40x60';

type TextSize = 'lg';
export type ModifiersHeading = (TextSize | GeneralTextStyle | Sizes)[];

interface HeadingProps extends React.HtmlHTMLAttributes<HTMLHeadingElement> {
  type?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  modifiers?: ModifiersHeading;
  children?: React.ReactNode;
  content?: string;
  isLine?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  type = 'h2',
  modifiers,
  children,
  content,
  isLine,
  ...props
}) => {
  const Element = type;

  return content ? (
    <Element
      {...props}
      className={mapModifiers('a-heading', type, modifiers)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
    />
  ) : (
    <Element {...props} className={mapModifiers('a-heading', type, modifiers, isLine && 'isLine')}>
      {children}
    </Element>
  );
};

export default Heading;
