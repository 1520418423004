import { CheckUrlResponseTypes, ShopDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getShopsService = async (slug: string): Promise<ShopDataTypes> => {
  const res = await axiosInstance.get(`shops/${slug}`);
  return res.data?.data;
};

export const sendCheckUrlService = async (
  params: { url: string }
): Promise<CheckUrlResponseTypes> => {
  const res = await axiosInstance.post('products/check', params);
  return res.data.data;
};

export const getShopService = async (params?: BaseRequestParamTypes &
{ bids?: number[] }):
  Promise<APIPaginationResponse<ShopDataTypes[]>> => {
  const res = await axiosInstance.get('shops', { params });
  return res.data;
};

export const getShopsSearchService = async (params: {
  keyword: string
}): Promise<APIPaginationResponse<ShopDataTypes[]>> => {
  const res = await axiosInstance.get('shops/search', { params });
  return res.data;
};
